import NProgress from 'nprogress'
import { isMobile } from 'react-device-detect'
import { notificationPopup } from './Constants/helpers'

export default ({
  dispatchFunctions = {
    success: () => {},
    error: () => {},
  },
  request = () => {},
  values = {},
  callback = () => {},
  successCallback = () => {},
  errorCallback = () => {},
}) => {
  NProgress.start()
  request(values).end((err, res) => {
    NProgress.done()
    if (!res || (res && !res.body)) {
      errorCallback()
      callback()
      notificationPopup({
        type: 'error',
        description: 'Ошибка! Что-то пошло не так.',
      })
      return
    }

    if (res.statusCode !== 200) {
      const error = res.body
      if (error) {
        if (error.violations) {
          dispatchFunctions.error(error.violations)
        } else if (error.description) {
          if (isMobile) {
            alert(error.description)
          } else {
            notificationPopup({
              type: 'error',
              description: error.description,
            })
          }
        }
      }
      callback()
      errorCallback(res.body)
      return
    }

    if (res.statusCode === 200 || res.ok) {
      if (res.body) {
        dispatchFunctions.success(res.body)
      }
      callback()
      successCallback()
    }
  })
}
