import React from 'react'
import PropTypes from 'prop-types'

const MaskBack = (props) => {
  if (props.onlyNumberCard) {
    return (
      <svg
        className={props.className}
        width="108px"
        height="113px"
        viewBox="0 0 108 113"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            transform="translate(-3996.000000, -232.000000)"
            className={props.classNameFill}
            fill={props.color || '#000'}
          >
            <g id="card-form" transform="translate(3785.000000, 184.000000)">
              <g id="card-2" transform="translate(49.000000, 47.000000)">
                <g id="card" transform="translate(0.000000, 0.733158)">
                  {/* eslint-disable-next-line max-len */}
                  <path d="M232.271924,102.800546 C187.221859,88.7945059 159.248598,45.7415353 162.551063,0.656679292 L256,0.652687903 C263.731986,0.652687903 270.000598,6.91953272 270.000598,14.6515192 C270.000598,14.6517095 270.000598,14.6518997 270.000598,14.6520899 L270.000598,112.988052 C257.347586,110.055631 244.726048,106.660066 232.271924,102.800546 Z" id="Fill-6" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
  return (
    <svg
      className={props.className}
      width="128px"
      height="107px"
      viewBox="0 0 128 107"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-2112.000000, -248.000000)"
          className={props.classNameFill}
          fill={props.color || '#000'}
        >
          <g id="card-front" transform="translate(1970.000000, 195.000000)">
            <g id="Fill-6-+-Fill-6-Mask">
              {/* eslint-disable-next-line max-len */}
              <path d="M212.271924,147.533704 C169.770203,134.319942 142.467963,95.2640659 142.288263,53.0291888 C142.288263,53.0108271 180.192168,53.0000929 255.999809,53.0000929 C263.731795,53.0000929 270,59.2679151 270,66.9999016 L270,67.0000929 L270,159.95046 C253.939342,156.553069 228.021086,152.414352 212.271924,147.533704 Z" id="Fill-6" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

MaskBack.propTypes = {
  onlyNumberCard: PropTypes.bool,
  className: PropTypes.string,
  classNameFill: PropTypes.string,
  color: PropTypes.string,
}

export default MaskBack
