import React from 'react'
import PropTypes from 'prop-types'

import {
  ProductsTable,
  Footer,
} from '../../Components'

import hand from './img/hand.png'

import styles from './form.module.scss'

function Presenter({
  isRu,
  number,
  currency,
  status,
  amountValue,
  sellerName,
  products,
  paymentId,
  currentDate,
  currencySymbol,
  handleOpenBill,
  currentYear,
}) {
  return (
    <>
      <div className={styles.background}>
        <div className={styles.backgroundCircle} />
        <div className={styles.base}>
          <div className={styles.wrap}>
            <header className={styles.header}>
              <div className={styles.headerTitle}>
                <span className={styles.date}>{currentDate}</span>
                <h1 className={styles.billNumber}>
                  {`${isRu ? 'Счёт' : 'Invoice'} ${number}`}
                </h1>
              </div>
              <div className={styles.sellerWrap}>
                <span className={styles.sellerTitle}>{`${isRu ? 'Продавец' : 'Seller name'}`}</span>
                <span className={styles.seller}>{sellerName}</span>
              </div>
            </header>
            <div className={styles.content}>
              <div className={styles.contentTable}>
                <ProductsTable
                  isRu={isRu}
                  currency={currency}
                  currencySymbol={currencySymbol}
                  products={products}
                  sum={amountValue}
                />
              </div>
              <div className={styles.contentBill}>
                <Footer
                  isRu={isRu}
                  status={status}
                  paymentId={paymentId}
                  sum={amountValue}
                  currency={currency}
                  handleCreateBill={handleOpenBill}
                />
              </div>
            </div>
            <div className={styles.footer}>
              <span className={styles.copyright} title={`tome, ${currentYear}`}>
                <img alt="hand" src={hand} className={styles.copyrightImage} />
                {`tome, ${currentYear}`}
              </span>
              <a
                href="https://tome.ge/offers/tome_deal.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.offer}
              >
                {`${isRu ? 'Публичная оферта' : 'Public offer'}`}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Presenter.propTypes = {
  isRu: PropTypes.bool.isRequired,
  number: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  amountValue: PropTypes.string.isRequired,
  sellerName: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  paymentId: PropTypes.string,
  currentDate: PropTypes.string.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  handleOpenBill: PropTypes.func.isRequired,
  currentYear: PropTypes.string.isRequired,
}

export default Presenter
