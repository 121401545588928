import React from 'react'
import PropTypes from 'prop-types'
import { Input, Tooltip } from 'antd'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'

import styles from './styles.module.scss'

const { TextArea } = Input

export default class AntInput extends React.PureComponent {
  static propTypes = {
    inputRef: PropTypes.shape({}),
    readOnly: PropTypes.bool,
    id: PropTypes.string,
    isAnimationError: PropTypes.bool,
    isInvalid: PropTypes.bool,
    isDisable: PropTypes.bool,
    isAuto: PropTypes.bool,
    autoSize: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({}),
    ]),
    textArea: PropTypes.bool,
    invalidTooltipText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
    tooltipPlacement: PropTypes.oneOf([
      'top', 'left', 'right', 'bottom',
      'topLeft', 'topRight', 'bottomLeft', 'bottomRight',
      'leftTop', 'leftBottom', 'rightTop', 'rightBottom',
    ]),
    wrapStyles: PropTypes.string,
    tooltipClassNames: PropTypes.string,
    name: PropTypes.string,
    autoComplete: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.oneOf(['small', 'default', 'large']),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    addonBefore: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
    prefix: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
    suffix: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
    styles: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
    autoFocus: PropTypes.bool,
    pattern: PropTypes.instanceOf(RegExp),
    label: PropTypes.string,
    labelClassName: PropTypes.string,
  }

  static defaultProps = {
    autoComplete: 'off',
    placeholder: '',
    type: 'text',
    size: 'default',
    autoFocus: false,
    isDisable: false,
    isAuto: false,
    isAnimationError: false,
    textArea: false,
    tooltipPlacement: 'topRight',
    label: null,
  }

  state = {
    isInputFocus: false,
    isInputHover: false,
  }

  onFocusWrap = () => this.setState({ isInputFocus: true })

  onBlurWrap = () => this.setState({ isInputFocus: false })

  // onHoverEnterInput = () => this.setState({ isInputHover: true })

  // onHoverLeaveInput = () => this.setState({ isInputHover: false })

  getIsShowTooltip = () => {
    const {
      isInvalid,
      isDisable,
      invalidTooltipText,
    } = this.props
    const {
      isInputFocus,
    } = this.state

    return (!!invalidTooltipText && !!invalidTooltipText.length)
      && isInputFocus
      && !isDisable
      && isInvalid
  }

  render() {
    const {
      textArea,
      autoSize,
      readOnly,
      id,
      name,
      autoComplete,
      placeholder,
      type,
      size,
      value,
      addonBefore,
      prefix,
      onChange,
      onFocus,
      onBlur,
      autoFocus,
      styles: outStyles,
      inputRef,
      isDisable,
      isAuto,
      invalidTooltipText,
      tooltipPlacement,
      wrapStyles,
      pattern,
      tooltipClassNames,
      isInvalid,
      isAnimationError,
      label,
      labelClassName,
      suffix,
      ...otherProps
    } = this.props
    const {
      isInputHover,
    } = this.state
    const {
      getIsShowTooltip,
      onFocusWrap,
      onBlurWrap,
    } = this

    return (
      <div
        onFocus={onFocusWrap}
        onBlur={onBlurWrap}
        className={styles.wrap}
      >
        <div className={styles.row}>
          {(!isMobile && label) && (
            <h6 className={classNames(styles.label, labelClassName)}>
              { label }
            </h6>
          )}
          <Tooltip
            placement={tooltipPlacement}
            title={invalidTooltipText || ''}
            visible={getIsShowTooltip()}
            overlayClassName={classNames('input-tooltip', isInputHover && 'input-tooltipPointless', tooltipClassNames)}
          >
            <div className={classNames(styles.inputWrap, wrapStyles)}>
              {textArea
                ? (
                  <TextArea
                    ref={inputRef}
                    {...{
                      autoSize,
                      readOnly,
                      id,
                      name,
                      autoComplete,
                      placeholder,
                      type,
                      size,
                      value,
                      prefix,
                      onChange,
                      onFocus,
                      onBlur,
                      autoFocus,
                      pattern,
                      ...otherProps,
                    }}
                    disabled={isDisable}
                    className={classNames(
                      outStyles,
                      isAuto && 'ant-input-affix-wrapper-auto'
                    )}
                  />
                )
                : (
                  <Input
                    ref={inputRef}
                    {...{
                      readOnly,
                      id,
                      name,
                      autoComplete,
                      type,
                      size,
                      value,
                      addonBefore,
                      prefix,
                      onChange,
                      onFocus,
                      onBlur,
                      autoFocus,
                      pattern,
                      ...otherProps,
                    }}
                    placeholder={isMobile ? (label || placeholder) : placeholder}
                    disabled={isDisable}
                    className={classNames(
                      outStyles,
                      isAuto && 'ant-input-affix-wrapper-auto'
                    )}
                  />
                )
              }
              <div className={styles.suffixWrap}>
                {isAuto ? <span className={styles.inputSuffix}>Авто</span> : suffix}
              </div>
              {isInvalid && <span className={classNames(styles.inputInvalid, textArea && styles.textAreaInvalid)} />}
            </div>
          </Tooltip>
        </div>
      </div>
    )
  }
}
