import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
  getFirstPartOfSum,
  getLastPartOfSum,
} from '../../Constants/helpers'

import styles from './products.module.scss'

const Product = (props) => {
  const {
    name,
    quantity,
    amount,
    currencySymbol,
  } = props

  return (
    <>
      <span className={classNames(styles.content)}>{name}</span>
      <span className={classNames(styles.content, styles.contentRight)}>{quantity}</span>
      <span className={classNames(styles.content, styles.contentRight)}>
        {`${getFirstPartOfSum(amount)},${getLastPartOfSum(amount)} ${currencySymbol}`}
      </span>
    </>
  )
}

Product.propTypes = {
  name: PropTypes.string,
  quantity: PropTypes.string,
  amount: PropTypes.string,
  currencySymbol: PropTypes.string,
}

export default Product
