import React from 'react'
import PropTypes from 'prop-types'
import { Popover } from 'antd'
import classNames from 'classnames'

import card from './img/paymentTypes/card.svg'
import qiwi from './img/paymentTypes/qiwi.svg'
import yandex from './img/paymentTypes/yandexMoney.svg'
import megafon from './img/paymentTypes/megafon.svg'
import mts from './img/paymentTypes/mts.svg'
import beeline from './img/paymentTypes/beeline.svg'
import tele2 from './img/paymentTypes/tele2.svg'
import webmoney from './img/paymentTypes/webmoney.svg'

import styles from './styles.module.scss'

class PaymentTypes extends React.PureComponent {
  paymentTypes = [
    {
      id: 'card',
      iconSrc: card,
    },
    {
      id: 'qiwi',
      iconSrc: qiwi,
    },
    {
      id: 'yandex',
      iconSrc: yandex,
    },
    {
      id: 'megafon',
      iconSrc: megafon,
    },
    {
      id: 'mts',
      iconSrc: mts,
    },
    {
      id: 'beeline',
      iconSrc: beeline,
    },
    {
      id: 'tele2',
      iconSrc: tele2,
    },
    {
      id: 'webmoney',
      iconSrc: webmoney,
    },
  ]

  constructor(props) {
    super(props)

    const { rates } = props
    const settlementMethods = this.generateSettlementMethods(rates)
    this.state = {
      paymentMethods: this.createPaymentSystemsArray({ rates, settlementMethods }),
    }
  }

  generateSettlementMethods = (rates) => {
    const settlementMethods = []
    rates.map((rate) => settlementMethods.push(rate.settlement_method))
    return settlementMethods
  }

  createPaymentSystemsArray = ({ settlementMethods, rates }) => {
    const {
      acceptTypes,
      disabledSettlementMethods,
    } = this.props
    const ratesArray = Object.values(rates)
    return settlementMethods.map((sm) => ({
      id: sm.id,
      isShow: !!acceptTypes.find((at) => at === sm.id),
      isEnable: !!ratesArray.find((rate) => rate.settlement_method.id === sm.id),
      isUserEnable: !disabledSettlementMethods.find((udm) => udm === sm.id),
      commission: ratesArray.find((rate) => rate.settlement_method.id === sm.id).commission,
    }))
  }

  getSystemImage = (id) => {
    if (this.paymentTypes.find(pt => pt.id === id)) {
      return this.paymentTypes.find(pt => pt.id === id).iconSrc
    }
    return ''
  }

  getIsShowPopup = (id) => {
    const {
      paymentMethods,
    } = this.state
    const isSinglePaymentMethod = paymentMethods.filter(ps => ps.isEnable && ps.isUserEnable).length === 1
    const isMethodActive = paymentMethods.find(ps => ps.id === id).isUserEnable
    return isSinglePaymentMethod && isMethodActive
  }

  getPaymentSystemMarkup = (system) => {
    const {
      className,
      handleClick,
    } = this.props
    const {
      getSystemImage,
      getIsShowPopup,
    } = this

    const button = (
      <button
        key={system.id}
        className={classNames(
          styles.button,
          className,
          (system.isEnable && system.isUserEnable) && styles.buttonChoose,
          !system.isEnable && styles.buttonPointless,
          !system.isShow && styles.buttonHide
        )}
        onClick={() => {
          if (system.isEnable) {
            handleClick(system)
          }
        }}
      >
        <img alt={system.id} src={getSystemImage(system.id)} />
      </button>
    )

    if (!system.isEnable) {
      return (
        <Popover
          key={system.id}
          placement="top"
          content="Временно не доступно"
          trigger="hover"
          overlayClassName="custom-popover"
        >
          { button }
        </Popover>
      )
    }
    if (getIsShowPopup(system.id)) {
      return (
        <Popover
          key={system.id}
          placement="top"
          content={`Комиссия: ${system.commission}%`}
          trigger="hover"
          overlayClassName="custom-popover custom-popover_paymentSystems-last"
        >
          { button }
        </Popover>
      )
    }
    return button
  }

  render() {
    const {
      paymentMethods,
    } = this.state
    const {
      getPaymentSystemMarkup,
    } = this

    return (
      <div className={styles.wrap}>
        {paymentMethods.map((system) => getPaymentSystemMarkup(system))}
      </div>
    )
  }
}

PaymentTypes.propTypes = {
  rates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  acceptTypes: PropTypes.arrayOf(PropTypes.oneOf([
    'card',
    'qiwi',
    'yandex',
    'megafon',
    'mts',
    'beeline',
    'tele2',
    'webmoney',
  ])),
  className: PropTypes.string,
  handleClick: PropTypes.func,

  disabledSettlementMethods: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    payment_enabled: PropTypes.bool,
    payout_enabled: PropTypes.bool,
  })),
}

PaymentTypes.defaultProps = {
  acceptTypes: [
    'card',
    'qiwi',
    'yandex',
    'megafon',
    'mts',
    'beeline',
    'tele2',
    'webmoney',
  ],
  disabledSettlementMethods: [],
}

export default PaymentTypes
