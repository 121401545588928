import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
  getFirstPartOfSum,
  getLastPartOfSum,
} from '../../Constants/helpers'

import Button from '../Button'

import visa from '../../img/card_types/visa.svg'
import mastercard from '../../img/card_types/mastercard.svg'
import sbp from '../../img/card_types/sbp.svg'

import oplacheno from './img/oplacheno.png'

import styles from './footer.module.scss'

const Footer = (props) => {
  const {
    isRu,
    sum,
    status,
    paymentId,
    currency,
    handleCreateBill,
  } = props

  const isStatusSuccess = (status === 'succeeded' || status === 'confirmation')

  return (
    <footer className={styles.wrap}>
      <div className={styles.sumWrap}>
        <span className={styles.sumTitle}>
          {`${isRu ? 'К оплате (' : 'Amount due ('}`}
          {currency}
          {')'}
        </span>
        <span className={styles.sum}>
          <span className={styles.sumBold}>{getFirstPartOfSum(sum)}</span>
          ,
          {getLastPartOfSum(sum)}
        </span>
      </div>
      {isStatusSuccess && paymentId
        ? (
          <Button
            isExternalLink
            target="_parent"
            type="white"
            wrapStyle={styles.createBill}
            to={`https://payment.tome.ru/${paymentId}/receipt`}
          >
            {`${isRu ? 'Чек платежа' : 'Receipt'}`}
          </Button>
        )
        : (
          <Button
            type="yellow"
            wrapStyle={styles.createBill}
            onClick={handleCreateBill}
          >
            {`${isRu ? 'Заплатить' : 'Pay now'}`}
          </Button>
      )}
      <div className={classNames(styles.bottom, isStatusSuccess && styles.bottomSucceeded)}>
        {isStatusSuccess
          ? (
            <img
              alt=""
              src={oplacheno}
              className={styles.bottomSucceededIco}
            />
          )
          : (
            <>
              <img
                alt="mastercard"
                src={mastercard}
                height={17}
                className={styles.bottomIcon}
              />
              <img
                alt="visa"
                src={visa}
                height={17}
                className={styles.bottomIcon}
              />
              <img
                alt="sbp"
                src={sbp}
                height={23}
                className={styles.bottomIcon}
              />
            </>
        )}
      </div>
    </footer>
  )
}

Footer.propTypes = {
  isRu: PropTypes.bool.isRequired,
  sum: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  paymentId: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  handleCreateBill: PropTypes.func.isRequired,
}

export default Footer
