import React from 'react'
import PropTypes from 'prop-types'

const MaskTop = (props) => {
  if (props.onlyNumberCard) {
    return (
      <svg
        className={props.className}
        width="124px"
        height="85px"
        viewBox="0 0 124 85"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            transform="translate(-3980.000000, -232.000000)"
            className={props.classNameFill}
            fill={props.color || '#000'}
          >
            <g id="card-form" transform="translate(3785.000000, 184.000000)">
              <g id="card-2" transform="translate(49.000000, 47.000000)">
                <g id="card" transform="translate(0.000000, 0.733158)">
                  {/* eslint-disable-next-line max-len */}
                  <path d="M213.271924,70.8005461 C179.135036,60.1874066 154.80351,32.867362 146.454974,0.656163384 C146.454974,0.653606243 182.970162,0.65210381 255.999969,0.65210381 C263.731957,0.65210381 270,6.92008765 270,14.6520752 L270,14.6521038 L270,85.0487843 C250.862879,81.344541 231.971484,76.5955201 213.271924,70.8005461 Z" id="Fill-6" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
  return (
    <svg
      className={props.className}
      width="142px"
      height="79px"
      viewBox="0 0 142 79"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-2098.000000, -248.000000)"
          className={props.classNameFill}
          fill={props.color || '#000'}
        >
          <g id="card-front" transform="translate(1970.000000, 195.000000)">
            <g id="Fill-6-+-Fill-6-Mask">
              {/* eslint-disable-next-line max-len */}
              <path d="M182.985552,104.61396 C158.259415,82.9141099 146.874467,51.6651846 149.48054,21.244907 C149.48054,21.244907 188.228765,38.4967284 265.725216,73.0003712 C272.788739,76.1452524 275.965423,84.4207993 272.820541,91.4843212 C272.820531,91.4843443 272.82052,91.4843673 272.82051,91.4843903 C255.300117,130.835266 246.538783,150.510197 246.536508,150.509184 C227.54152,137.759974 200.262211,119.748204 182.985552,104.61396 Z" id="Fill-6" transform="translate(213.815415, 85.877046) rotate(-24.000000) translate(-213.815415, -85.877046) " />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

MaskTop.propTypes = {
  onlyNumberCard: PropTypes.bool,
  className: PropTypes.string,
  classNameFill: PropTypes.string,
  color: PropTypes.string,
}

export default MaskTop
