import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import styles from './styles.module.scss'

const ClearButton = ({
  onClick,
  innerLink,
  stylesWrap,
}) => {
  if (innerLink) {
    return (
      <NavLink
        to={onClick}
        className={classNames(styles.clear, stylesWrap)}
      />
    )
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(styles.clear, stylesWrap)}
    />
  )
}

ClearButton.propTypes = {
  onClick: PropTypes.func,
  innerLink: PropTypes.bool,
  stylesWrap: PropTypes.string,
}

ClearButton.defaultProps = {
  innerLink: false,
  onClick: () => {},
}

export default ClearButton
