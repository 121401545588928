import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  Input,
  InputNumber,
} from 'antd'

import {
  AmountInput,
  Button,
  CloseButton,
} from '../../Components'

import styles from './requisites.module.scss'

const Product = ({
  isFirst,
  name,
  handleChangeName,
  count,
  handleChangeCount,
  amount,
  currencySymbol,
  handleChangeAmount,
  handleAddProduct,
  handleRemoveProduct,
}) => (
  <div className={styles.product}>
    <div className={styles.productContent}>
      <Input
        size="small"
        value={name}
        placeholder="Наименование"
        className={styles.lineInput}
        onChange={handleChangeName}
      />
      <div className={styles.productBottom}>
        <div className={styles.productBottomItem}>
          <AmountInput
            size="small"
            name="amount"
            value={amount}
            max={100000}
            currencySymbol={currencySymbol}
            handleChange={handleChangeAmount}
            className={classNames(styles.lineInput, styles.lineInputAmount)}
          />
        </div>
        <span className={styles.productCross}>x</span>
        <div className={classNames(styles.productBottomItem, styles.productBottomCount)}>
          <InputNumber
            min={0}
            max={1000}
            step={1}
            value={count}
            name="number"
            size="small"
            onChange={handleChangeCount}
            className={styles.lineInput}
          />
        </div>
      </div>
    </div>
    {isFirst
      ? (
        <Button
          size="small"
          onClick={handleAddProduct}
          wrapStyle={styles.productButton}
        >
          +
        </Button>
      )
      : (
        <CloseButton
          stylesWrap={styles.removeButton}
          onClick={handleRemoveProduct}
        />
      )}
  </div>
)

Product.propTypes = {
  isFirst: PropTypes.bool,
  name: PropTypes.string,
  handleChangeName: PropTypes.func,
  count: PropTypes.number,
  handleChangeCount: PropTypes.func,
  amount: PropTypes.string,
  currencySymbol: PropTypes.string.isRequired,
  handleChangeAmount: PropTypes.func,
  handleAddProduct: PropTypes.func,
  handleRemoveProduct: PropTypes.func,
}

export default Product
