import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles.module.scss'

const EmptyCard = (props) => {
  const {
    className,
    iconSrc,
    iconClassName,
    title,
    largeTitle,
    text,
  } = props
  return (
    <div className={classNames(styles.wrap, className)}>
      <img alt={iconSrc} src={iconSrc} className={classNames(styles.image, iconClassName)} />
      {title && (
        <h2 className={styles.title}>
          { title }
        </h2>
      )}
      {largeTitle && (
        <h1 className={styles.largeTitle}>
          { largeTitle }
        </h1>
      )}
      {text && (
        <p className={styles.text}>
          { text }
        </p>
      )}
    </div>
  )
}

EmptyCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  iconSrc: PropTypes.node,
  iconClassName: PropTypes.string,
  title: PropTypes.string,
  largeTitle: PropTypes.string,
  text: PropTypes.string,
}

export default EmptyCard
