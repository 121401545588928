import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import styles from './styles.module.scss'

const SettingsMarkupLabel = (props) => {
  const {
    children,
    to,
    isGray,
    isButton,
    handleButtonClick,
  } = props

  if (isButton) {
    return (
      <button
        onClick={handleButtonClick}
        className={classNames(styles.label, isGray && styles.labelLinkGray, props.className)}
      >
        { children }
      </button>
    )
  }

  if (to) {
    return (
      <Link
        to={to}
        className={classNames(styles.label, styles.labelLink, isGray && styles.labelLinkGray, props.className)}
      >
        { children }
      </Link>
    )
  }

  return (
    <h6 className={classNames(styles.label, isGray && styles.labelLinkGray, props.className)}>
      { children }
    </h6>
  )
}

SettingsMarkupLabel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  isGray: PropTypes.bool,
  isButton: PropTypes.bool,
  handleButtonClick: PropTypes.func,
  className: PropTypes.string,
}

const SettingsMarkupRightSide = (props) => (
  <div className={classNames(styles.right, props.className)}>
    { props.children }
  </div>
)

SettingsMarkupRightSide.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  className: PropTypes.string,
}

const SettingsMarkupLeftSide = (props) => (
  <div className={classNames(styles.left, props.className)}>
    { props.children }
  </div>
)

SettingsMarkupLeftSide.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  className: PropTypes.string,
}

const SettingsMarkupCheck = (props) => {
  const {
    isLoading,
    handleClick,
    to,
    children,
    className,
  } = props
  if (handleClick) {
    return (
      <div
        onClick={handleClick}
        className={classNames(styles.checkWrap, isLoading && styles.checkWrapLoading, className)}
      >
        { children }
      </div>
    )
  }
  if (to) {
    return (
      <Link
        to={to}
        className={classNames(styles.checkWrap, isLoading && styles.checkWrapLoading, className)}
      >
        { children }
      </Link>
    )
  }

  return (
    <div className={classNames(styles.checkWrap, isLoading && styles.checkWrapLoading, className)}>
      { children }
    </div>
  )
}

SettingsMarkupCheck.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  handleClick: PropTypes.func,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  isLoading: PropTypes.bool,
  className: PropTypes.string,
}

SettingsMarkupCheck.defaultProps = {
  isLoading: false,
  handleClick: null,
}

const SettingsMarkupTitle = (props) => (
  <h4 className={classNames(styles.title, props.className)}>
    { props.children }
  </h4>
)

SettingsMarkupTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  className: PropTypes.string,
}

const SettingsMarkupContent = (props) => (
  <div className={classNames(styles.content, props.className)}>
    { props.children }
  </div>
)

SettingsMarkupContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.any),
  ]),
  className: PropTypes.string,
}

const SettingsMarkupRow = (props) => (
  <div
    className={classNames(
      styles.row,
      props.footer && styles.rowFooter,
      props.small && styles.rowSmall,
      props.className
    )}
  >
    { props.children }
  </div>
)

SettingsMarkupRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  className: PropTypes.string,
  footer: PropTypes.bool,
  small: PropTypes.bool,
}


class SettingsMarkup extends PureComponent {
  render() {
    return (
      <div className={classNames('global-flat-box-shadow', styles.wrap)}>
        { this.props.children }
      </div>
    )
  }
}

SettingsMarkup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.bool,
    PropTypes.shape({}),
  ]),
}

export {
  SettingsMarkup,
  SettingsMarkupRow,
  SettingsMarkupContent,
  SettingsMarkupTitle,
  SettingsMarkupCheck,
  SettingsMarkupLeftSide,
  SettingsMarkupRightSide,
  SettingsMarkupLabel,
}
