import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import logo from './img/logo.svg'
import close from './img/close.svg'
import mimi from './img/mimi.svg'
import line from './img/line.svg'
import arrow from './img/arrow_right_small.svg'

import styles from './createFormWrap.module.scss'

const CreateFormWrap = ({
  headerTitle,
  handleClose,
  children,
}) => (
  <div className={styles.wrap}>
    <div className={styles.backgroundCircle} />
    <div className={styles.header}>
      <NavLink to="/" className={styles.logoLink}>
        <img alt="Tome" src={logo} className={styles.logo} />
      </NavLink>
      <div className="base-layout">
        <div className={styles.headerContent}>
          <div className={styles.headerLeft}>
            {headerTitle && (
              <div className={classNames(styles.headerTitleWrap, styles.headerTitleWrapChoose)}>
                <h1 className={styles.headerTitle}>
                  {headerTitle}
                </h1>
              </div>
            )}
            {handleClose && (
              <button
                onClick={handleClose}
                className={styles.close}
              >
                <img alt="Close" src={close} />
              </button>
            )}
            {/*
            {!handleClose && (
              <div className={styles.headerTitleWrap}>
                <h2 className={styles.headerTitle}>API</h2>
              </div>
            )}
            */}
          </div>
          <img alt="mimi" src={mimi} className={styles.mimiImage} />
        </div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://lk.tome.ru/signin"
          className={styles.signIn}
        >
          <img alt="" src={line} className={styles.signInLine} />
          <span>Войти</span>
          <img alt="->" src={arrow} className={styles.signInImage} />
        </a>
      </div>
    </div>
    <div className="base-layout">
      <div className={styles.contentWrap}>
        {children}
      </div>
    </div>
  </div>
)

CreateFormWrap.propTypes = {
  headerTitle: PropTypes.string,
  handleClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
}

export default CreateFormWrap
