import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import copy from 'copy-to-clipboard'
import { isMobile } from 'react-device-detect'

import { notificationPopup } from '../../Constants/helpers'

import {
  Button,
  BoxInfo,
} from '../../Components'

import wave from '../../img/wave_settings.png'
import header from './img/header.svg'

import img1 from './img/img_1.png'
import img2 from './img/img_2.png'
import img3 from './img/img_3.png'
import img4 from './img/img_4.png'

import styles from './link.module.scss'

class Link extends PureComponent {
  constructor(props) {
    super(props)
    props.push(`#link-${props.invoiceId}`)
  }

  handleCopy = () => {
    const { invoiceId } = this.props
    copy(`https://invoice.tome.ru/${invoiceId}`)
    notificationPopup({
      type: 'error',
      description: 'Скопировано.',
    })
  }

  render() {
    const { invoiceId } = this.props
    const { handleCopy } = this
    return (
      <>
        <div className={classNames('global-flat-box-shadow', styles.wrap)}>
          <div className={styles.header}>
            <img alt="header" src={header} className={styles.headerImg} />
            <img alt="wave" src={wave} className={styles.headerWave} />
          </div>
          <div className={styles.content}>
            <span className={styles.inviteLink}>{`https://invoice.tome.ru/${invoiceId}`}</span>
            <Button
              type="white"
              onClick={handleCopy}
              wrapStyle={styles.copyButton}
            >
              Копировать
            </Button>
          </div>
        </div>
        <BoxInfo
          data={[
            {
              icon: <img alt="" src={img1} width={isMobile ? 25 : 22} style={{ top: -1 }} />,
              title: 'Как передать',
              text: 'Просто отправьте в\u00A0чат сообщением или письмом на\u00A0почту',
              disable: false,
            },
            {
              icon: <img alt="" src={img2} width={isMobile ? 25 : 20} style={{ top: -1 }} />,
              title: 'Как оплатить',
              text: 'Достаточно перейти по ссылке и\u00A0нажать кнопку «оплатить»',
              disable: false,
            },
            {
              icon: <img alt="" src={img3} width={isMobile ? 25 : 20} style={{ top: -1 }} />,
              title: 'Как я узнаю',
              text: 'Подпишитесь на\u00A0важные уведомления и\u00A0после оплаты мы отправим смс',
              disable: false,
            },
            {
              icon: <img alt="" src={img4} height={22} />,
              title: 'Как забрать',
              text: 'Доход можно вывести на\u00A0банковскую карту или расчётный счёт',
              disable: false,
            },
          ]}
        />
      </>
    )
  }
}

Link.propTypes = {
  push: PropTypes.func.isRequired,
  invoiceId: PropTypes.string.isRequired,
}

export default Link
