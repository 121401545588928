import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import flag from 'country-code-emoji'
import classNames from 'classnames'

import Api from '../../Api'
import apiRequest from '../../request'

import {
  Button,
} from '../../Components'

import styles from './preview.module.scss'

class Preview extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      countriesList: [],
    }
    this.getCountries()
    props.push('')
  }

  getCountries = () => {
    apiRequest({
      dispatchFunctions: {
        success: (body) => {
          this.setState({
            countriesList: this.getFlagsList(body.items),
          })
        },
      },
      request: () => Api.getCountries(),
    })
  }

  getFlagsList = (countriesList) => countriesList.map((country) => {
    return {
      flag: flag(country.id),
      name: country.name,
    }
  })

  render() {
    const { handleSubmit } = this.props
    const { countriesList } = this.state
    return (
      <>
        <div className={classNames('global-flat-box-shadow', styles.wrap)}>
          <h2 className={styles.title}>
            Удобный способ
            <br />
            расчётов с клиентами
          </h2>
          <div className={styles.itemsWrap}>
            <div className={classNames(styles.item, styles.itemFirst)}>
              <span role="img" aria-label="" className={styles.itemIcon}>📢</span>
              <span className={styles.itemText}>
                Поддержка
                <br />
                разных языков
              </span>
            </div>
            <div className={classNames(styles.item, styles.itemSecond)}>
              <span role="img" aria-label="" className={styles.itemIcon}>🌏</span>
              <span className={styles.itemText}>
                Оплата для
                <br />
                более
                <strong>{' 40 '}</strong>
                стран
              </span>
            </div>
            <div className={classNames(styles.item, styles.itemThird)}>
              <span role="img" aria-label="" className={styles.itemIcon}>💳</span>
              <span className={styles.itemText}>
                Выплата на
                <br />
                карту или счёт
              </span>
            </div>
          </div>
          <Button
            type="yellow"
            onClick={handleSubmit}
            wrapStyle={styles.button}
          >
            Выставить счёт
          </Button>
        </div>
        {countriesList.length > 0 ? (
          <div className={classNames('global-flat-box-shadow', styles.flagListWrap)}>
            {countriesList.map((country) => (
              <div
                key={country.name}
                className={styles.flagListItem}
              >
                {country.flag}
                <span className={styles.flagListName}>{country.name}</span>
              </div>
            ))}
          </div>
        ) : null}
      </>
    )
  }
}

Preview.propTypes = {
  push: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default Preview
