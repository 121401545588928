import { message } from 'antd'
import PropTypes from 'prop-types'

export const isMoney = (sum) => {
  const regex = /^\d*(\.?\d{0,2})?$/
  return regex.test(sum) && sum[0] !== '.'
}

export const getIsInputInvalid = ({ inputName, errors }) => {
  if (errors.length) {
    const currentErrors = errors.find((error) => error.parameter === inputName)
    return !!currentErrors
  }
  return false
}

export const getInvalidText = ({ inputName, errors }) => {
  if (errors.length) {
    const currentError = errors.find((error) => error.parameter === inputName)
    if (currentError) {
      return currentError.description
    }
    return ''
  }

  return ''
}

export const formattedPhone = (phone) => {
  if (!phone) {
    return ''
  }

  let pattern = '+### (###) ### ###'
  let phoneLength = 12
  if (phone[0] === '7') {
    pattern = '+# (###) ### ## ##'
    phoneLength = 11
  }

  let i = -1
  if (phone.length === phoneLength) {
    return pattern.replace(/#/g, () => phone[i += 1])
  }
  return phone
}

export const getFirstPartOfSum = (sum) =>
  parseInt(sum, 10).toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, '$1').slice(0, -2)

export const getLastPartOfSum = (sum) => {
  let stringSum = sum
  if (typeof sum !== 'string') {
    stringSum = sum.toString()
  }
  const lastPart = stringSum.split('.')[1]
  if (!lastPart) {
    return '00'
  }
  if (lastPart.length === 1) {
    return lastPart.concat('0')
  }
  return lastPart
}

export const notificationPopup = ({
  type = '',
  duration = 4.5,
  description = '',
}) => {
  const config = {
    key: `open${Date.now()}`,
    duration,
    top: 50,
  }
  message.destroy()
  message.config(config)

  switch (type) {
    case 'error':
      message.error(description)
      break
    case 'success':
      message.success(description)
      break
    case 'info':
      message.info(description)
      break
    default:
      message.info(description)
      break
  }
}

notificationPopup.PropTypes = {
  type: PropTypes.oneOf(['info', 'success', 'error']),
  duration: PropTypes.number,
  message: PropTypes.string,
  description: PropTypes.string,
}
