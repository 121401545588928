import React from 'react'
import PropTypes from 'prop-types'

const MaskTopShadow = (props) => {
  if (props.onlyNumberCard) {
    return (
      <svg className={props.className} width="124px" height="66px" viewBox="0 0 124 66" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
          <linearGradient x1="0%" y1="0%" x2="70%" y2="55%" id="linearGradient-1">
            <stop stopColor="#FFFFFF" stopOpacity="0.3" offset="0%" />
            <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%" />
          </linearGradient>
        </defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="02.71_tome_payment_cards_colours_1920" transform="translate(-3980.000000, -232.000000)" fill="url(#linearGradient-1)">
            <g id="card-form" transform="translate(3785.000000, 184.000000)">
              <g id="card-2" transform="translate(49.000000, 47.000000)">
                <g id="card" transform="translate(0.000000, 0.733158)">
                  <path d="M213.271924,70.8005461 C179.135036,60.1874066 154.80351,32.867362 146.454974,0.656163384 C146.454974,0.653606243 182.970162,0.65210381 255.999969,0.65210381 C263.731957,0.65210381 270,6.92008765 270,14.6520752 L270,14.6521038 L270,85.0487843 C250.862879,81.344541 231.971484,76.5955201 213.271924,70.8005461 Z" id="Fill-6" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
  return (
    <svg className={props.className} width="142px" height="64px" viewBox="0 0 142 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <linearGradient x1="0%" y1="0%" x2="70%" y2="55%" id="linearGradient-1">
          <stop stopColor="#FFFFFF" stopOpacity="0.3" offset="0%" />
          <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%" />
        </linearGradient>
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="02.70_tome_payment_cards_colours_1920" transform="translate(-3457.000000, -248.000000)" fill="url(#linearGradient-1)">
          <g id="card-front" transform="translate(3329.000000, 195.000000)">
            <g id="Fill-6-+-Fill-6-Mask">
              <path d="M193.271924,115.533704 C161.857349,105.76693 138.746598,81.8502919 128.754323,53 L255.999962,53 C263.73195,53 269.999964,59.2680126 269.999964,67 C269.999964,110.075075 269.99837,131.612575 269.99588,131.612575 C247.457524,127.691549 215.210588,122.332473 193.271924,115.533704 Z" id="Fill-6" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

MaskTopShadow.propTypes = {
  onlyNumberCard: PropTypes.bool,
  className: PropTypes.string,
}

export default MaskTopShadow
