import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
  getFirstPartOfSum,
  getLastPartOfSum,
} from '../../Constants/helpers'

import Product from './product'

import styles from './products.module.scss'

const ProductsTable = (props) => {
  const {
    isRu,
    currency,
    currencySymbol,
    products,
    sum,
  } = props

  return (
    <div className={styles.table}>
      <div className={styles.header}>
        <span>{`${isRu ? 'Наименование' : 'Description'}`}</span>
      </div>
      <div className={styles.header}>
        <span>{`${isRu ? 'Кол-во' : 'Qty'}`}</span>
      </div>
      <div className={styles.header}>
        <span>{`${isRu ? 'Цена' : 'Price'}`}</span>
      </div>
      <div className={styles.separator} />
      {products.map((product) => (
        <>
          <Product
            name={product.name}
            quantity={product.quantity}
            amount={product.price}
            currencySymbol={currencySymbol}
          />
          <div className={styles.emptySeparator} />
        </>
      ))}
      <div className={classNames(styles.separator, styles.separatorLast)} />
      <span className={styles.sumTitle}>
        {`${isRu ? 'К оплате (' : 'Amount due ('}`}
        {currency}
        {')'}
      </span>
      <span className={styles.amount}>
        {`${getFirstPartOfSum(sum)},${getLastPartOfSum(sum)} ${currencySymbol}`}
      </span>
    </div>
  )
}

ProductsTable.propTypes = {
  isRu: PropTypes.bool.isRequired,
  currency: PropTypes.string,
  currencySymbol: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    quantity: PropTypes.string.isRequired,
  })).isRequired,
  sum: PropTypes.string,
}

export default ProductsTable
