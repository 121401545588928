import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ReactInputMask from 'react-input-mask'
import { Form as AntForm, Tooltip } from 'antd'

import Input from '../Input'

import ClearButton from './ClearButton'
import MaskTop from './MaskTop'
import MaskTopShadow from './MaskTopShadow'
import MaskBack from './MaskBack'

import backLine from './img/back_line.svg'
import PciSecurity from './img/pci_security.svg'

import './card-number-input.scss'
import './input-tooltip-csc.scss'
import styles from './styles.module.scss'

const FormItem = AntForm.Item

export default function Presenter({
  wrapStyles,
  autoFocus,
  onlyNumberCard,
  number,
  numberMask,
  numberValidStatus,
  expiryMonth,
  expiryYear,
  dateValidStatus,
  csc,
  cscName,
  cscValidStatus,
  bankColors,

  handleInputKeyDown,
  onInputChange,
  getCardTypeImage,
  clearNumberField,
  getInputError,

  numberRef,
  expiryMonthRef,
  expiryYearRef,
  cscRef,
}) {
  return (
    <div className={classNames(styles.wrap, wrapStyles)}>
      <div
        className={classNames(
          styles.card,
          !onlyNumberCard && styles.cardTop
        )}
      >
        <MaskBack
          onlyNumberCard={onlyNumberCard}
          color={bankColors[1]}
          className={classNames(
            styles.mask,
            styles.maskBack,
            bankColors[1] && styles.maskBackColors
          )}
          classNameFill={styles.maskFill}
        />
        <MaskTop
          onlyNumberCard={onlyNumberCard}
          color={bankColors[0]}
          className={classNames(
            styles.mask,
            styles.maskTop,
            bankColors[0] && styles.maskTopColors
          )}
          classNameFill={styles.maskFill}
        />
        <MaskTopShadow onlyNumberCard={onlyNumberCard} className={styles.mask} />
        <div className={classNames(styles.cardHeader, onlyNumberCard && styles.cardHeaderNumberOnly)}>
          {onlyNumberCard
            ? getCardTypeImage()
            : (
              <span className={styles.safe}>Ваш банк</span>
            )
          }
        </div>
        <FormItem
          hasFeedback
          validateStatus={!getInputError('number') ? numberValidStatus : 'error'}
          className="card-number-input"
        >
          <ReactInputMask mask={numberMask} maskPlaceholder="" value={number} onChange={onInputChange}>
            <Input
              autoFocus={autoFocus}
              inputRef={numberRef}
              size="small"
              id="cc-number"
              autoComplete="cc-number"
              name="number"
              placeholder="Номер карты"
              onKeyDown={handleInputKeyDown}
              isInvalid={getInputError('number')}
              invalidTooltipText={getInputError('number') && getInputError('number').description}
              suffix={
                onlyNumberCard
                  ? (
                    <>
                      {number.length > 0 && (
                        <ClearButton onClick={clearNumberField} />
                      )}
                    </>
                  )
                  : getCardTypeImage()
              }
            />
          </ReactInputMask>
        </FormItem>
        {onlyNumberCard
          ? <img alt="PCI Security" src={PciSecurity} className={styles.pciSecurity} />
          : (
            <div className={styles.dateWrap}>
              <span className={styles.dateTitle}>VALID THRU:</span>
              <div className={styles.date}>
                <div>
                  <div className={styles.dateInput}>
                    <FormItem
                      hasFeedback
                      validateStatus={!getInputError('expiryMonth') ? dateValidStatus : 'error'}
                      className="short-input"
                    >
                      <Input
                        value={expiryMonth}
                        onChange={onInputChange}
                        isShort
                        inputRef={expiryMonthRef}
                        size="small"
                        id="cc-exp-month"
                        autoComplete="cc-exp-month"
                        name="expiryMonth"
                        placeholder="ММ"
                        tooltipClassNames={styles.tooltipSmall}
                        onKeyDown={handleInputKeyDown}
                        isInvalid={getInputError('expiryMonth')}
                        invalidTooltipText={
                          getInputError('expiryMonth') && getInputError('expiryMonth').description
                        }
                      />
                    </FormItem>
                  </div>
                  <span className={styles.slash}>/</span>
                  <div className={styles.dateInput}>
                    <FormItem
                      hasFeedback
                      validateStatus={!getInputError('expiryYear') ? dateValidStatus : 'error'}
                      className="short-input"
                    >
                      <Input
                        value={expiryYear}
                        onChange={onInputChange}
                        isShort
                        inputRef={expiryYearRef}
                        size="small"
                        id="cc-exp-year"
                        autoComplete="cc-exp-year"
                        name="expiryYear"
                        tooltipClassNames={styles.tooltipSmall}
                        placeholder="ГГ"
                        onKeyDown={handleInputKeyDown}
                        isInvalid={getInputError('expiryYear')}
                        invalidTooltipText={getInputError('expiryYear') && getInputError('expiryYear').description}
                      />
                    </FormItem>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
      {!onlyNumberCard && (
        <div className={classNames(styles.card, styles.cardBottom)}>
          <div className={styles.cardBottomLine}>
            <img alt="backLine" src={backLine} />
          </div>
          <div className={styles.csc}>
            <FormItem
              hasFeedback
              validateStatus={!getInputError('csc') ? cscValidStatus : 'error'}
              className="card-csc-input"
            >
              <ReactInputMask mask="999" maskPlaceholder="" value={csc} onChange={onInputChange}>
                <Input
                  isShort
                  inputRef={cscRef}
                  size="small"
                  id="cc-csc"
                  autoComplete="cc-csc"
                  name="csc"
                  placeholder={cscName}
                  tooltipClassNames={styles.tooltipCsc}
                  isInvalid={getInputError('csc')}
                  invalidTooltipText={getInputError('csc') && getInputError('csc').description}
                  onKeyDown={handleInputKeyDown}
                  suffix={(
                    <Tooltip
                      placement="topRight"
                      title={'Три цифры с обратной стороны\u00A0карты'}
                      overlayClassName={classNames('input-tooltip', 'input-info-tooltip')}
                    >
                      <div className={styles.cscInfo} />
                    </Tooltip>
                  )}
                />
              </ReactInputMask>
            </FormItem>
          </div>
        </div>
      )}
    </div>
  )
}

Presenter.propTypes = {
  wrapStyles: PropTypes.string,
  autoFocus: PropTypes.bool.isRequired,
  onlyNumberCard: PropTypes.bool.isRequired,
  number: PropTypes.string.isRequired,
  numberMask: PropTypes.string.isRequired,
  numberValidStatus: PropTypes.string.isRequired,
  expiryMonth: PropTypes.string.isRequired,
  expiryYear: PropTypes.string.isRequired,
  dateValidStatus: PropTypes.string.isRequired,
  csc: PropTypes.string.isRequired,
  cscName: PropTypes.string.isRequired,
  cscValidStatus: PropTypes.string.isRequired,
  bankColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleInputKeyDown: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  getCardTypeImage: PropTypes.func.isRequired,
  clearNumberField: PropTypes.func.isRequired,
  getInputError: PropTypes.func.isRequired,

  numberRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  expiryMonthRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  expiryYearRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  cscRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
}
