import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ReactInputMask from 'react-input-mask'
import { isMobile } from 'react-device-detect'
import classNames from 'classnames'

import Input from '../Input'

import styles from './styles.module.scss'

class PhoneInput extends PureComponent {
  static propTypes = {
    autoFocus: PropTypes.bool,
    inputRef: PropTypes.shape({}),
    isInvalid: PropTypes.bool.isRequired,
    isDisable: PropTypes.bool,
    isAuto: PropTypes.bool,
    label: PropTypes.string,
    size: PropTypes.oneOf(['small', 'default', 'large']),
    invalidTooltipText: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    invalidTooltipText: '',
    isDisable: false,
    size: 'default',
  }

  constructor(props) {
    super(props)

    this.state = {
      mask: '',
    }
  }

  handleChange = (e) => {
    const { target: { value } } = e
    if (value && value[0] === '7') {
      this.setState({ mask: '9 999 999 99 99' })
    } else {
      this.setState({ mask: '999 999 999 999' })
    }
    this.props.onChange(e)
  }

  render() {
    const {
      autoFocus,
      inputRef,
      isInvalid,
      isDisable,
      isAuto,
      label,
      value,
      size,
      invalidTooltipText,
    } = this.props
    const {
      mask,
    } = this.state
    const {
      handleChange,
    } = this

    return (
      <ReactInputMask
        mask={mask}
        maskPlaceholder=""
        value={value}
        onChange={handleChange}
      >
        <Input
          isAuto={isAuto}
          inputRef={inputRef}
          autoFocus={autoFocus && !isMobile}
          isDisable={isDisable}
          size={size}
          name="phone"
          type="tel"
          label={!isMobile ? label : null}
          placeholder="Номер телефона"
          styles={styles.input}
          isInvalid={isInvalid}
          invalidTooltipText={invalidTooltipText}
          prefix={(
            <i
              className={classNames(
                styles.prefix,
                isDisable && styles.prefixDisable,
                isInvalid && styles.prefixInvalid
              )}
            >
              +
            </i>
          )}
        />
      </ReactInputMask>
    )
  }
}

export default PhoneInput
