import Requests from './requests'

const API_URL = 'https://i.tome.ge/api'
const API_ENDPOINTS = {
  getCountries: `${API_URL}/directory/countries?pattern=card_card`,
  getCurrencies: `${API_URL}/directory/currencies?pattern=card_card`,
  getLanguages: `${API_URL}/directory/languages?pattern=card_card`,
  getRates: `${API_URL}/rates`,
  postInvoice: `${API_URL}/v1/invoices`,
  getInvoice: (id) => `${API_URL}/invoices/${id}`,
  postCreatePayment: (id) => `${API_URL}/invoices/${id}/payments`,
}

export default class Api {
  static getCountries() {
    const endpoint = API_ENDPOINTS.getCountries

    return Requests.getRequest(endpoint)
  }

  static getCurrencies() {
    const endpoint = API_ENDPOINTS.getCurrencies

    return Requests.getRequest(endpoint)
  }

  static getLanguages() {
    const endpoint = API_ENDPOINTS.getLanguages

    return Requests.getRequest(endpoint)
  }

  static getRates(data) {
    const endpoint = API_ENDPOINTS.getRates

    return Requests.getRequest(endpoint, data)
  }

  static postInvoice(body) {
    const endpoint = API_ENDPOINTS.postInvoice

    return Requests.postRequest(endpoint, body)
  }

  static getInvoice(id) {
    const endpoint = API_ENDPOINTS.getInvoice(id)

    return Requests.getRequest(endpoint)
  }

  static postCreatePayment(id) {
    const endpoint = API_ENDPOINTS.postCreatePayment(id)
    return Requests.postRequest(endpoint)
  }
}
