import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import crossGray from './img/cross_gray.svg'
import crossRed from './img/cross_red.svg'

import styles from './styles.module.scss'

const content = () => (
  <>
    <img
      alt="close"
      src={crossGray}
      className={classNames(styles.closeCross, styles.closeCrossGray)}
    />
    <img
      alt="close"
      src={crossRed}
      className={classNames(styles.closeCross, styles.closeCrossRed)}
    />
  </>
)

const CloseButton = ({
  onClick,
  innerLink,
  stylesWrap,
}) => {
  if (innerLink) {
    return (
      <NavLink
        to={onClick}
        className={classNames(styles.close, stylesWrap)}
      >
        { content() }
      </NavLink>
    )
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(styles.close, stylesWrap)}
    >
      { content() }
    </button>
  )
}

CloseButton.propTypes = {
  onClick: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]),
  innerLink: PropTypes.bool,
  stylesWrap: PropTypes.string,
}

CloseButton.defaultProps = {
  innerLink: false,
  onClick: () => {},
}

export default CloseButton
