import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles.module.scss'

const ButtonTabs = (props) => {
  const {
    wrapClassName,
    tabs,
    currentTab,
    isDisable,
    handlePress,
  } = props

  return (
    <div className={classNames(styles.wrap, wrapClassName)}>
      {tabs.map((tab) => (
        <button
          key={tab.id}
          type="button"
          className={classNames(
            styles.tab,
            currentTab === tab.id && styles.tabActive,
            isDisable && styles.tabDisable
          )}
          onClick={() => handlePress(tab)}
        >
          {tab.name}
        </button>
      ))}
    </div>
  )
}

ButtonTabs.propTypes = {
  wrapClassName: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  currentTab: PropTypes.string.isRequired,
  isDisable: PropTypes.bool,
  handlePress: PropTypes.func,
}

export default ButtonTabs
