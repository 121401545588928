import request from 'superagent'

export default class Requests {
  static getRequest(endpoint, queryParams) {
    return request
      .get(endpoint)
      .query(queryParams)
      .accept('application/json')
  }

  static postRequest(endpoint, body) {
    return request
      .post(endpoint)
      .send(body)
      .accept('application/json')
  }
}
