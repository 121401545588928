import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles.module.scss'

const BoxInfo = ({ className, data }) => (
  <div className={classNames('global-flat-box-shadow', styles.wrap, className)}>
    <div className={styles.content}>
      {data.map((item) => (
        <div
          key={item.title}
          className={classNames(styles.item, item.disable && styles.itemDisable)}
        >
          <div className={classNames(styles.itemIcon, item.active && styles.itemIconActive)}>
            { item.icon }
          </div>
          <div className={styles.itemContent}>
            <h5 className={styles.itemTitle}>
              { item.title }
            </h5>
            <p className={styles.itemText}>
              { item.text }
            </p>
          </div>
        </div>
      ))}
    </div>
  </div>
)

BoxInfo.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
    active: PropTypes.bool,
    title: PropTypes.string,
    text: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
    disable: PropTypes.bool,
  })),
}

export default BoxInfo
