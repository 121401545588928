import React from 'react'
import PropTypes from 'prop-types'
import { Select as AntSelect, Tooltip } from 'antd'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'

import ArrowDown from './arrowDown'
import styles from './styles.module.scss'

export default class Select extends React.Component {
  static propTypes = {
    showSearch: PropTypes.bool,
    mode: PropTypes.oneOf(['default', 'multiple', 'tags']),
    size: PropTypes.oneOf(['small', 'default', 'large']),
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.arrayOf(PropTypes.any),
    ]).isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onSearch: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.number),
    ]),
    wrapStyles: PropTypes.string,
    isDisable: PropTypes.bool,
    isClearShow: PropTypes.bool,
    isDropDownHide: PropTypes.bool,
    filterOption: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.func,
    ]),

    isInvalid: PropTypes.bool,
    invalidTooltipText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
    tooltipPlacement: PropTypes.oneOf([
      'top', 'left', 'right', 'bottom',
      'topLeft', 'topRight', 'bottomLeft', 'bottomRight',
      'leftTop', 'leftBottom', 'rightTop', 'rightBottom',
    ]),
    tooltipClassNames: PropTypes.string,
    label: PropTypes.string,
    labelClassName: PropTypes.string,
  }

  static defaultProps = {
    showSearch: false,
    mode: 'default',
    size: 'default',
    filterOption: true,
    tooltipPlacement: 'topRight',
    label: null,
  }

  constructor(props) {
    super(props)

    this.state = {
      isFocus: false,
      isHover: false,
      isShowTooltip: false,
    }
  }


  componentDidMount() {
    this.getIsShowTooltip()
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isInvalid: prevIsInvalid,
      isDisable: prevIsDisable,
    } = prevProps
    const {
      isInvalid,
      isDisable,
    } = this.props
    const {
      isFocus: prevIsFocus,
      isHover: prevIsHover,
    } = prevState
    const {
      isFocus,
      isHover,
    } = this.state

    if (
      (prevIsInvalid !== isInvalid)
      || (prevIsDisable !== isDisable)
      || (prevIsFocus !== isFocus)
      || (prevIsHover !== isHover)
    ) {
      this.getIsShowTooltip()
    }
  }

  onDropdownVisibleChange = (isFocus) => this.setState({ isFocus })

  onMouseEnter = () => this.setState({ isHover: true })

  onMouseLeave = () => this.setState({ isHover: false })

  getIsShowTooltip = () => {
    const {
      isInvalid,
      isDisable,
      invalidTooltipText,
    } = this.props
    const {
      isFocus,
      isHover,
    } = this.state

    const isShowTooltip = (!!invalidTooltipText && !!invalidTooltipText.length)
      && (isFocus || isHover)
      && !isDisable
      && isInvalid

    if (isShowTooltip !== this.state.isShowTooltip) {
      this.setState({ isShowTooltip })
    }
  }

  render() {
    const {
      showSearch,
      mode,
      size,
      placeholder,
      onChange,
      onSearch,
      value,
      wrapStyles,
      isDisable,
      isClearShow,
      isDropDownHide,
      filterOption,
      children,

      tooltipPlacement,
      invalidTooltipText,
      tooltipClassNames,

      label,
      labelClassName,
    } = this.props
    const {
      isHover,
      isShowTooltip,
    } = this.state
    const {
      onDropdownVisibleChange,
      onMouseEnter,
      onMouseLeave,
    } = this

    return (
      <Tooltip
        placement={tooltipPlacement}
        title={invalidTooltipText || ''}
        visible={isShowTooltip}
        overlayClassName={classNames('input-tooltip', isHover && 'input-tooltipPointless', tooltipClassNames)}
      >
        <div className={classNames(styles.row, wrapStyles)}>
          {(!isMobile && label) && (
            <h6 className={classNames(styles.label, labelClassName)}>
              { label }
            </h6>
          )}
          <div
            className={classNames(
              'ant-selection',
              `ant-selection-${size}`,
              isDropDownHide && 'ant-selection-hide-dropdown',
              styles.wrap,
            )}
          >
            <AntSelect
              showSearch={showSearch}
              allowClear={isClearShow}
              size={size}
              mode={mode}
              dropdownClassName={`ant-selection__dropdown ${isDropDownHide && 'ant-selection__dropdown-hide'}`}
              placeholder={placeholder}
              disabled={isDisable}
              onChange={onChange}
              onSearch={onSearch}
              onDropdownVisibleChange={onDropdownVisibleChange}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              value={value || undefined}
              filterOption={filterOption}
              style={{ width: '100%' }}
              locale={{ emptyText: 'Нет данных' }}
            >
              { children }
            </AntSelect>
            {!isClearShow && (
              <ArrowDown
                styles={classNames(
                  styles.arrowDown,
                  this.state.isFocus && styles.arrowDownOpen
                )}
              />
            )}
          </div>
        </div>
      </Tooltip>
    )
  }
}
