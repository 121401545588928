import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import cardValidator from 'card-validator'
import CardInfo from 'card-info'

import Presenter from './presenter'
import PresenterOneLine from './presenter.oneLine'

import mastercard from './img/card_types/mastercard.svg'
import maestro from './img/card_types/maestro.svg'
import maestroMastercardWhite from './img/card_types/maestro_mastercard_white.svg'
import visa from './img/card_types/visa.svg'
import visaWhite from './img/card_types/visa_white.svg'
import mir from './img/card_types/mir.svg'
import mirWhite from './img/card_types/mir_white.svg'

import styles from './styles.module.scss'

export default class CreditCard extends React.PureComponent {
  static propTypes = {
    oneLine: PropTypes.bool,
    oneLineSize: PropTypes.oneOf([
      'small',
      'default',
      'large',
    ]),
    autoFocus: PropTypes.bool,
    data: PropTypes.shape({
      number: PropTypes.string,
      expiryMonth: PropTypes.string,
      expiryYear: PropTypes.string,
      csc: PropTypes.string,
    }),
    errors: PropTypes.arrayOf(PropTypes.shape({
      parameter: PropTypes.string,
      description: PropTypes.string,
    })),
    wrapStyles: PropTypes.string,
    onlyNumberCard: PropTypes.bool,
    getCardType: PropTypes.func,
    setIsFormValid: PropTypes.func,
    handleNumberClear: PropTypes.func,
    handleInputChange: PropTypes.func.isRequired,
    handleSubmitForm: PropTypes.func.isRequired,
  }

  static defaultProps = {
    oneLine: false,
    oneLineSize: 'default',
    autoFocus: true,
    data: {
      number: '',
      expiry: '',
      expiryMonth: '',
      expiryYear: '',
      csc: '',
    },
    errors: [],
    onlyNumberCard: false,
    handleNumberClear: () => {},
    setIsFormValid: () => {},
    getCardType: () => {},
  }

  constructor(props) {
    super(props)

    this.numberRef = React.createRef()
    this.numberShortRef = React.createRef()

    this.expiryRef = React.createRef()

    this.expiryMonthRef = React.createRef()
    this.expiryYearRef = React.createRef()

    this.cscRef = React.createRef()
    this.cscShortRef = React.createRef()

    this.state = {
      numberValidStatus: '',
      isFormValid: false,
      isNumberPotentiallyValid: true,
      isNumberValid: false,
      numberType: '',
      isDateValid: true,
      dateValidStatus: '',
      cscName: 'CVV',
      cscValidStatus: '',
      bankNameEn: '',
      bankColors: ['', ''],
      numberMask: '9999 9999 9999 9999 999',
      ...props.data,
    }
  }

  componentDidMount() {
    this.componentMount = true
    if (this.props.autoFocus && !this.props.oneLine) {
      setTimeout(() => {
        if (this.componentMount) {
          this.numberRef.current.focus()
        }
      }, 350)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const prevErrors = prevProps.errors
    const { errors } = this.props

    if (prevErrors.length < errors.length) {
      this.focusOnFirstError()
    }
    if (!prevState.isFormValid && this.state.isFormValid && this.props.oneLine) {
      this.numberShortRef.current.blur()
      this.expiryRef.current.blur()
      this.cscShortRef.current.blur()
    }
  }

  componentWillUnmount() {
    this.componentMount = false
  }

  getLastPartOfErrorName = (string) => {
    const split = string.split('.')
    return split[split.length - 1]
  }

  focusOnFirstError = () => {
    const {
      errors,
      oneLine,
    } = this.props
    const isErrorInclude = inputName => !!errors.find(error => this.getLastPartOfErrorName(error.parameter) === inputName)

    if (isErrorInclude('number')) {
      if (oneLine) {
        this.numberShortRef.current.focus()
      } else {
        this.numberRef.current.input.focus()
      }
    } else if (isErrorInclude('expiry')) {
      this.expiryRef.current.focus()
    } else if (isErrorInclude('expiryMonth')) {
      if (oneLine) {
        this.expiryRef.current.focus()
      } else {
        this.expiryMonthRef.current.input.focus()
      }
    } else if (isErrorInclude('expiryYear')) {
      if (oneLine) {
        this.expiryRef.current.focus()
      } else {
        this.expiryYearRef.current.input.focus()
      }
    } else if (isErrorInclude('csc')) {
      if (oneLine) {
        this.cscShortRef.current.focus()
      } else {
        this.cscRef.current.input.focus()
      }
    }
  }

  getCardTypeImage = () => {
    const { onlyNumberCard, oneLine } = this.props
    const { numberType } = this.state
    const isShowWhiteLogo = onlyNumberCard

    switch (numberType) {
      case 'maestro':
        if (isShowWhiteLogo) {
          return <img alt="Maestro" src={maestroMastercardWhite} className={styles.maestroMastercardWhite} />
        }
        return <img alt="Maestro" src={maestro} />
      case 'mastercard':
        if (isShowWhiteLogo) {
          return <img alt="Mastercard" src={maestroMastercardWhite} className={styles.maestroMastercardWhite} />
        }
        return <img alt="Mastercard" src={mastercard} />
      case 'visa':
        if (isShowWhiteLogo) {
          return <img alt="Visa" src={visaWhite} className={styles.visaWhite} />
        }
        return <img alt="Visa" src={visa} />
      case 'mir':
        if (isShowWhiteLogo) {
          return <img alt="Mir" src={mirWhite} className={styles.mirWhite} />
        }
        return <img alt="Mir" src={mir} />
      default:
        if (onlyNumberCard && !oneLine) {
          return (
            <>
              <img alt="Mastercard" src={mastercard} className={styles.logoLine} />
              <img alt="Maestro" src={maestro} className={styles.logoLine} />
              <img alt="Visa" src={visa} className={styles.logoLine} />
              <img alt="Mir" src={mir} className={styles.logoLine} />
            </>
          )
        }
        return null
    }
  }

  handleInputKeyDown = (e) => {
    const { name, value } = e.target
    if (
      value.length === 0
      && this.state[name].length === 0
      && e.keyCode === 8
    ) {
      this.handleDeleteLastSymbol(name)
    }
    if (e.keyCode === 13) {
      e.preventDefault()
      if (this.props.oneLine) {
        const isNumberFocused = this.numberShortRef.current === document.activeElement
        const isExpiryFocused = this.expiryRef.current === document.activeElement
        const isCscFocused = this.cscShortRef.current === document.activeElement

        if (isNumberFocused) {
          this.expiryRef.current.focus()
        } else if (isExpiryFocused) {
          this.cscShortRef.current.focus()
        } else if (isCscFocused) {
          this.props.handleSubmitForm()
        }
      } else {
        this.props.handleSubmitForm()
      }
    }
  }

  beforeInputChange = ({ nextState }) => {
    let { value } = nextState
    if (
      nextState.enteredString
      && nextState.enteredString.length >= 5
      && nextState.enteredString.includes('/')
    ) {
      const { enteredString } = nextState
      value = `${enteredString[0]}${enteredString[1]} / ${enteredString[enteredString.length - 2]}${enteredString[enteredString.length - 1]}`
    }

    return {
      ...nextState,
      value,
    }
  }

  onInputChange = (e) => {
    const { name, value } = e.target
    let newValue = value
    if (name === 'expiryMonth' && value.length > 2) {
      return
    }
    if (name === 'expiryYear') {
      if (value.length === 4) {
        newValue = value[2] + value[3]
      } else if (value.length > 2) {
        return
      }
    }
    if (name === 'csc' && value.length > 3) {
      return
    }

    if (name === 'expiry') {
      this.expiryValidation(newValue, () => this.inputChangeCallback({ name, newValue }))
    } else if (name === 'expiryMonth') {
      const numberValue = Number(newValue)
      let monthValue = newValue
      if (numberValue === 0 && newValue.length === 2) {
        monthValue = '01'
      }
      if (numberValue > 12) {
        monthValue = '12'
      }
      if (numberValue > 0 && numberValue <= 12) {
        if (newValue[0] !== '1') {
          monthValue = `0${numberValue}`
          this.expiryYearRef.current.input.focus()
        } else {
          monthValue = newValue
        }
      }
      this.expiryMonthChangeValidation(monthValue, () => this.inputChangeCallback({ name, newValue }))
    } else if (name === 'number') {
      this.numberChangeValidation(newValue, () => this.inputChangeCallback({ name, newValue }))
    } else if (name === 'expiryYear') {
      this.expiryYearChangeValidation(newValue, () => this.inputChangeCallback({ name, newValue }))
    } else if (name === 'csc') {
      this.cscChangeValidation(newValue, () => this.inputChangeCallback({ name, newValue }))
    }
  }

  inputChangeCallback = ({ name, newValue }) => {
    this.getIsSubmitEnable()
    this.props.handleInputChange({ paymentType: 'card', name, value: newValue.replace(/\s/g, '') })
  }

  getBankBackgroundColors = (nameEn = '') => {
    if (
      nameEn === 'Sberbank'
      || nameEn === 'AK Bars'
      || nameEn === 'Avangard'
      || nameEn === 'Jugra'
      || nameEn === 'OTP Bank'
      || nameEn === 'Rossiysky Capital'
      || nameEn === 'Rosselkhozbank'
      || nameEn === 'SKB-Bank'
    ) {
      return ['#34A219', '#34A219']
    }
    if (
      nameEn === 'Alfa-Bank'
      || nameEn === 'Absolut Bank'
      || nameEn === 'Азиатско-Тихоокеанский Банк'
      || nameEn === 'Credit Europe Bank'
      || nameEn === 'Cetelem Bank'
      || nameEn === 'HCF Bank'
      || nameEn === 'Mosсow Industrial Bank'
      || nameEn === 'Credit Bank of Moscow'
      || nameEn === 'Mosoblbank'
      || nameEn === 'MTS Bank'
      || nameEn === 'Renaissance Capital'
      || nameEn === 'Rosgosstrakh Bank'
      || nameEn === 'Bank Saint Petersburg'
      || nameEn === 'UniCredit Bank'
    ) {
      return ['#C91B19', '#C91B19']
    }
    if (
      nameEn === 'Promsvyazbank'
      || nameEn === 'B&N Bank Public'
      || nameEn === 'Citibank'
      || nameEn === 'Globexbank'
      || nameEn === 'Gazprombank'
      || nameEn === 'Novikombank'
      || nameEn === 'Otkritie FC'
      || nameEn === 'Pochtabank'
      || nameEn === 'SMP Bank'
      || nameEn === 'Transcapitalbank'
      || nameEn === 'Uralsib'
      || nameEn === 'Eastern Express Bank'
      || nameEn === 'Bank Vozrozhdenie'
      || nameEn === 'VTB Bank'
      || nameEn === 'VTB 24'
      || nameEn === 'Zenit'
      || nameEn === 'Rosevrobank'
      || nameEn === 'Sovcombank bank'
      || nameEn === 'Rossiya'
      || nameEn === 'Russian Standard Bank'
      || nameEn === 'UBRD'
      || nameEn === 'Sviaz-Bank'
    ) {
      return ['#243784', '#243784']
    }
    if (
      nameEn === 'Rosbank bank'
      || nameEn === 'Raiffeisenbank bank'
      || nameEn === 'Tinkoff Bank'
      || nameEn === 'Trust'
      || nameEn === 'Russian Regional Development Bank'
      || nameEn === 'Yandex.Money'
    ) {
      return ['#45474B', '#45474B']
    }
    return ['#45474B', '#45474B']
  }

  getNumberMask = (cardType = '') => {
    switch (cardType) {
      case 'american-express':
        return '9999 999999 99999'
      case 'maestro':
        return '9999 9999 9999 9999 999'
      case 'diners-club':
        return '9999 999999 9999'
      case 'visa':
        return '9999 9999 9999 9999'
      case 'mastercard':
        return '9999 9999 9999 9999'
      case 'mir':
        return '9999 9999 9999 9999'
      case 'discover':
        return '9999 9999 9999 9999'
      case 'jcb':
        return '9999 9999 9999 9999'
      case 'unionpay':
        return '9999 9999 9999 9999'
      default:
        return '9999 9999 9999 9999 999'
    }
  }

  numberChangeValidation = (value = '', callback = () => {}) => {
    const {
      onlyNumberCard,
      getCardType,
    } = this.props
    const {
      bankNameEn,
      bankColors,
    } = this.state

    let cardInfo = {}
    let newBankNameEn = bankNameEn
    let newBankColors = bankColors
    const validNumber = cardValidator.number(value)

    if (value.length < 7) {
      newBankNameEn = ''
      if (validNumber.card) {
        newBankColors = this.getBankBackgroundColors()
      } else {
        newBankColors = ['', '']
      }
    } else if (value.length >= 7 && !bankNameEn) {
      cardInfo = new CardInfo(value)
      newBankNameEn = cardInfo.bankNameEn
      if (validNumber.card) {
        newBankColors = this.getBankBackgroundColors(cardInfo.bankNameEn)
      } else {
        newBankColors = ['', '']
      }
    }
    const newNumberMask = this.getNumberMask(validNumber.card ? validNumber.card.type : '')
    this.setState({
      number: value,
      bankNameEn: newBankNameEn,
      bankColors: newBankColors,
      isNumberPotentiallyValid: validNumber.isPotentiallyValid,
      isNumberValid: validNumber.isValid,
      numberType: validNumber.card ? validNumber.card.type : '',
      numberMask: newNumberMask,
      cscName: validNumber.card ? validNumber.card.code.name : 'CVV',
    }, () => {
      callback()
      getCardType(this.state.numberType)
      if (validNumber.isValid && !onlyNumberCard) {
        if (this.props.oneLine) {
          this.expiryRef.current.focus()
        } else {
          this.expiryMonthRef.current.input.focus()
        }
      }
    })
  }

  expiryValidation = (expiry = '', callback = () => {}) => {
    let isDateValid = false
    let dateValidStatus = ''
    if (expiry.length === 7) {
      const enterDate = moment(`${expiry[0] + expiry[1]}/${expiry[5] + expiry[6]}`, 'MM/YY')
      const diffDate = enterDate.diff(moment())
      isDateValid = diffDate > 0
      dateValidStatus = diffDate > 0 ? '' : 'error'
    }
    this.setState({
      expiry,
      isDateValid,
      dateValidStatus,
    }, () => {
      callback()
      if (expiry.length === 7 && isDateValid) {
        if (this.props.oneLine) {
          this.cscShortRef.current.focus()
        } else {
          this.cscRef.current.input.focus()
        }
      }
    })
  }

  expiryMonthChangeValidation = (value = '', callback = () => {}) => {
    const { expiryYear } = this.state
    this.validationDate({
      expiryMonth: value,
      expiryYear,
      callback: () => {
        callback()
        if (value.length === 2) {
          this.expiryYearRef.current.input.focus()
        }
      },
    })
  }

  expiryYearChangeValidation = (value = '', callback = () => {}) => {
    const { expiryMonth } = this.state
    this.validationDate({
      expiryMonth,
      expiryYear: value,
      callback: () => {
        callback()
        if (this.state.isDateValid && value.length === 2) {
          if (this.props.oneLine) {
            this.cscShortRef.current.focus()
          } else {
            this.cscRef.current.input.focus()
          }
        }
      },
    })
  }

  cscChangeValidation = (value = '', callback = () => {}) => this.setState({ csc: value }, callback)

  handleDeleteLastSymbol = (fieldName) => {
    const { oneLine } = this.props
    switch (fieldName) {
      case 'expiry':
        this.numberShortRef.current.focus()
        break
      case 'expiryMonth':
        this.numberRef.current.input.focus()
        break
      case 'expiryYear':
        this.expiryMonthRef.current.input.focus()
        break
      case 'csc':
        if (oneLine) {
          this.expiryRef.current.focus()
        } else {
          this.expiryYearRef.current.input.focus()
        }
        break
      default:
        break
    }
  }

  clearNumberField = () => {
    this.props.handleNumberClear()
    this.setState({ number: '' }, () => {
      this.numberChangeValidation('')
      if (this.props.oneLine) {
        this.numberShortRef.current.focus()
      } else {
        this.numberRef.current.input.focus()
      }
      this.props.handleInputChange({ paymentType: 'card', name: 'number', value: '' })
    })
  }

  validationDate = ({ expiryMonth, expiryYear, callback }) => {
    if (expiryMonth.length === 2 && expiryYear.length === 2) {
      const enterDate = moment(`${expiryMonth}/${expiryYear}`, 'MM/YY')
      const diffDate = enterDate.diff(moment())
      this.setState({
        expiryMonth,
        expiryYear,
        isDateValid: diffDate > 0,
        dateValidStatus: diffDate > 0 ? '' : 'error',
      }, callback)
    } else {
      this.setState({
        expiryMonth,
        expiryYear,
        isDateValid: false,
        dateValidStatus: '',
      }, callback)
    }
  }

  getInputError = (parameter) => {
    const {
      isNumberPotentiallyValid,
      expiry,
      expiryMonth,
      expiryYear,
      isDateValid,
    } = this.state

    switch (parameter) {
      case 'number':
        if (!isNumberPotentiallyValid) {
          return {
            parameter: 'number',
            description: 'Проверьте номер карты',
          }
        }
        return this.props.errors.find(error => this.getLastPartOfErrorName(error.parameter) === parameter)
      case 'expiry':
        if (!isDateValid && expiry.length === 4) {
          return {
            parameter: 'expiry',
            description: 'Истек срок действия карты',
          }
        }
        return this.props.errors.find(err => err.parameter === 'expiryMonth' || err.parameter === 'expiryYear')
      case 'expiryYear':
        if (!isDateValid && expiryMonth.length === 2 && expiryYear.length === 2) {
          return {
            parameter: 'expiryYear',
            description: 'Истек срок действия карты',
          }
        }
        return this.props.errors.find(error => this.getLastPartOfErrorName(error.parameter) === parameter)
      default:
        return this.props.errors.find(error => this.getLastPartOfErrorName(error.parameter) === parameter)
    }
  }

  getIsSubmitEnable = () => {
    const { onlyNumberCard } = this.props
    const {
      isNumberValid,
      isDateValid,
      expiry,
      expiryMonth,
      expiryYear,
      csc,
    } = this.state
    const { getInputError } = this

    let valid = false
    const isCardNoError = !(
      getInputError('number')
      || getInputError('expiry')
      || getInputError('csc')
    )
    if (onlyNumberCard) {
      valid = isCardNoError && isNumberValid
    } else {
      valid = isCardNoError
        && isNumberValid
        && isDateValid
        && (
          expiry.length === 7
          || (
            expiryMonth.length === 2
            && expiryYear.length === 2
          )
        )
        && csc.length === 3
    }

    this.setState({ isFormValid: valid })
    this.props.setIsFormValid(valid)
    return valid
  }

  _submitValidation = () => {
    const { onlyNumberCard } = this.props
    const {
      number,
      isNumberValid,
      isDateValid,
      expiry,
      expiryMonth,
      expiryYear,
      csc,
    } = this.state

    const errors = []

    if (onlyNumberCard) {
      if (!number.length) {
        errors.push({
          parameter: 'number',
          description: 'Не может быть пустым',
        })
      } else if (!isNumberValid) {
        errors.push({
          parameter: 'number',
          description: 'Проверьте номер карты',
        })
      }
    } else {
      if (!number.length) {
        errors.push({
          parameter: 'number',
          description: 'Не может быть пустым',
        })
      }
      if (!isNumberValid) {
        errors.push({
          parameter: 'number',
          description: 'Проверьте номер карты',
        })
      }
      if (expiry.length < 4) {
        errors.push({
          parameter: 'expiry',
          description: 'Введите дату как на карте',
        })
      }
      if (expiryMonth.length < 2) {
        errors.push({
          parameter: 'expiryMonth',
          description: 'Введите дату как на карте',
        })
      }
      if (expiryYear.length < 2) {
        errors.push({
          parameter: 'expiryYear',
          description: 'Введите дату как на карте',
        })
      }
      if (!isDateValid) {
        errors.push({
          parameter: 'expiryYear',
          description: 'Истек срок действия карты',
        })
      }
      if (csc.length < 3) {
        errors.push({
          parameter: 'csc',
          description: 'Некорректный код',
        })
      }
    }
    return errors
  }

  render() {
    const {
      oneLine,
      oneLineSize,
      autoFocus,
      wrapStyles,
      onlyNumberCard,
    } = this.props
    const {
      number,
      numberMask,
      expiry,
      expiryMonth,
      expiryYear,
      csc,
      numberValidStatus,
      isDateValid,
      dateValidStatus,
      cscName,
      cscValidStatus,
      bankColors,
    } = this.state
    const {
      handleInputKeyDown,
      beforeInputChange,
      onInputChange,
      getCardTypeImage,
      clearNumberField,
      getInputError,

      numberRef,
      numberShortRef,
      expiryRef,
      expiryMonthRef,
      expiryYearRef,
      cscRef,
      cscShortRef,
    } = this

    if (oneLine) {
      return (
        <PresenterOneLine
          {...{
            oneLineSize,
            onlyNumberCard,
            wrapStyles,
            autoFocus,
            number,
            numberMask,
            numberValidStatus,
            expiry,
            dateValidStatus,
            csc,
            cscName,
            cscValidStatus,

            handleInputKeyDown,
            beforeInputChange,
            onInputChange,
            getCardTypeImage,
            getInputError,

            numberShortRef,
            expiryRef,
            cscShortRef,
          }}
        />
      )
    }

    return (
      <Presenter
        {...{
          autoFocus,
          wrapStyles,
          onlyNumberCard,
          number,
          numberMask,
          numberValidStatus,
          expiryMonth,
          expiryYear,
          isDateValid,
          dateValidStatus,
          csc,
          cscName,
          cscValidStatus,
          bankColors,

          handleInputKeyDown,
          onInputChange,
          getCardTypeImage,
          clearNumberField,
          getInputError,

          numberRef,
          expiryMonthRef,
          expiryYearRef,
          cscRef,
        }}
      />
    )
  }
}
