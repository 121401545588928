import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  // Input,
  Select as AntSelect,
  Form as AntForm,
} from 'antd'

import {
  getIsInputInvalid,
  getInvalidText,
} from '../../Constants/helpers'

import {
  CardPCI,
  Input,
  Select,
  ButtonTabs,
  CreditCard,
  PhoneInput,
  PaymentTypes,

  SettingsMarkupRow,
  SettingsMarkupContent,
  SettingsMarkupLeftSide,
  SettingsMarkupRightSide,
  SettingsMarkupLabel,
} from '../../Components'

import Product from './product'

import styles from './requisites.module.scss'

const { Option } = AntSelect
const FormItem = AntForm.Item

function Presenter({
  errors,
  currenciesList,
  languagesList,
  ratesList,
  language,
  currency,
  currencySymbol,
  products,
  settlementMethod,
  card,
  handleChangeLanguage,
  handleChangeCurrency,
  handleChangeName,
  handleChangeCount,
  handleChangeAmount,
  handleAddProduct,
  handleRemoveProduct,
  handleChangeSettlementMethod,
  handleChangeCard,
  phone,
  phoneRef,
  handleChangePhone,
  firstNameRef,
  firstName,
  handleChangeFirstName,
  lastNameRef,
  lastName,
  handleChangeLastName,
  handleSubmit,
}) {
  return (
    <CardPCI
      submitText="Создать счёт"
      handleSubmitForm={handleSubmit}
      pciText={(
        <>
          <span>Данные для</span>
          <span>внешнего счёта</span>
        </>
      )}
    >
      <div className={styles.wrap}>
        <SettingsMarkupRow small>
          <SettingsMarkupContent>
            <SettingsMarkupLeftSide>
              <SettingsMarkupLabel>Выбор языка</SettingsMarkupLabel>
            </SettingsMarkupLeftSide>
            <SettingsMarkupRightSide className={styles.selectWarp}>
              <Select
                size="small"
                placeholder="Не выбрано"
                wrapStyles={styles.lineInput}
                onChange={handleChangeLanguage}
                value={language}
              >
                {languagesList.map((lang) => (
                  <Option key={lang.id} value={lang.id}>{lang.name}</Option>
                ))}
              </Select>
            </SettingsMarkupRightSide>
          </SettingsMarkupContent>
          <SettingsMarkupContent>
            <SettingsMarkupLeftSide>
              <SettingsMarkupLabel>Выбор валюты</SettingsMarkupLabel>
            </SettingsMarkupLeftSide>
            <SettingsMarkupRightSide className={styles.selectWarp}>
              <Select
                size="small"
                placeholder="Не выбрано"
                wrapStyles={styles.lineInput}
                onChange={handleChangeCurrency}
                value={currency}
              >
                {currenciesList.map((cur) => (
                  <Option key={cur.id} value={cur.id}>{cur.name}</Option>
                ))}
              </Select>
            </SettingsMarkupRightSide>
          </SettingsMarkupContent>
        </SettingsMarkupRow>
        <SettingsMarkupRow small>
          <SettingsMarkupContent>
            <SettingsMarkupLeftSide>
              <SettingsMarkupLabel>Продавец</SettingsMarkupLabel>
            </SettingsMarkupLeftSide>
            <SettingsMarkupRightSide>
              <div className={styles.nameWrap}>
                <FormItem
                  hasFeedback
                  validateStatus={getIsInputInvalid({ inputName: 'seller.first_name', errors }) ? 'error' : ''}
                  className={classNames(styles.lineInput, styles.nameInput)}
                >
                  <Input
                    autoFocus
                    inputRef={firstNameRef}
                    size="small"
                    value={firstName}
                    placeholder="Имя"
                    onChange={handleChangeFirstName}
                    isInvalid={getIsInputInvalid({ inputName: 'seller.first_name', errors })}
                    invalidTooltipText={getInvalidText({ inputName: 'seller.first_name', errors })}
                  />
                </FormItem>
                <FormItem
                  hasFeedback
                  validateStatus={getIsInputInvalid({ inputName: 'seller.last_name', errors }) ? 'error' : ''}
                  className={classNames(styles.lineInput, styles.nameInput)}
                >
                  <Input
                    inputRef={lastNameRef}
                    size="small"
                    value={lastName}
                    placeholder="Фамилия"
                    onChange={handleChangeLastName}
                    isInvalid={getIsInputInvalid({ inputName: 'seller.last_name', errors })}
                    invalidTooltipText={getInvalidText({ inputName: 'seller.last_name', errors })}
                  />
                </FormItem>
              </div>
            </SettingsMarkupRightSide>
          </SettingsMarkupContent>
        </SettingsMarkupRow>
        <SettingsMarkupRow small>
          <SettingsMarkupContent>
            <SettingsMarkupLeftSide>
              <SettingsMarkupLabel>Товары / услуги</SettingsMarkupLabel>
            </SettingsMarkupLeftSide>
            <SettingsMarkupRightSide>
              <div className={styles.productList}>
                {products.map((product, productIndex) => (
                  <Product
                    key={productIndex}
                    isFirst={productIndex === 0}
                    name={product.name}
                    count={product.count}
                    amount={product.amount}
                    currencySymbol={currencySymbol}
                    handleChangeName={(e) => handleChangeName(productIndex, e)}
                    handleChangeCount={(e) => handleChangeCount(productIndex, e)}
                    handleChangeAmount={(amount) => handleChangeAmount(productIndex, amount)}
                    handleAddProduct={handleAddProduct}
                    handleRemoveProduct={() => handleRemoveProduct(productIndex)}
                  />
                ))}
              </div>
            </SettingsMarkupRightSide>
          </SettingsMarkupContent>
        </SettingsMarkupRow>
        <SettingsMarkupRow small>
          <SettingsMarkupContent>
            <SettingsMarkupLeftSide>
              <SettingsMarkupLabel>Как оплатить</SettingsMarkupLabel>
            </SettingsMarkupLeftSide>
            <SettingsMarkupRightSide className={styles.tabsWrap}>
              <PaymentTypes rates={ratesList} />
            </SettingsMarkupRightSide>
          </SettingsMarkupContent>
        </SettingsMarkupRow>
        <SettingsMarkupRow small>
          <SettingsMarkupContent>
            <SettingsMarkupLeftSide>
              <SettingsMarkupLabel>Как оплатить</SettingsMarkupLabel>
            </SettingsMarkupLeftSide>
            <SettingsMarkupRightSide className={styles.tabsWrap}>
              <ButtonTabs
                tabs={[
                  {
                    id: 'self_employed',
                    name: 'tome',
                  },
                ]}
                currentTab={settlementMethod}
                handlePress={handleChangeSettlementMethod}
                wrapClassName={styles.tabs}
              />
              {settlementMethod === 'card' ? (
                <CreditCard
                  oneLine
                  oneLineSize="small"
                  onlyNumberCard
                  handleSubmitForm={() => {}}
                  errors={errors}
                  handleInputChange={handleChangeCard}
                  data={{
                    number: card,
                  }}
                />
              ) : null}
              {settlementMethod === 'self_employed' ? (
                <FormItem
                  hasFeedback
                  validateStatus={getIsInputInvalid({ inputName: 'customer.phone', errors }) ? 'error' : ''}
                >
                  <PhoneInput
                    inputRef={phoneRef}
                    size="small"
                    value={phone}
                    onChange={handleChangePhone}
                    isInvalid={getIsInputInvalid({ inputName: 'customer.phone', errors })}
                    invalidTooltipText={getInvalidText({ inputName: 'customer.phone', errors })}
                  />
                </FormItem>
              ) : null}
            </SettingsMarkupRightSide>
          </SettingsMarkupContent>
        </SettingsMarkupRow>
      </div>
    </CardPCI>
  )
}

Presenter.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.shape({})),
  currenciesList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    rate: PropTypes.number,
    face_value: PropTypes.number,
  })).isRequired,
  languagesList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  ratesList: PropTypes.arrayOf(PropTypes.shape({})),
  language: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    amount: PropTypes.string.isRequired,
  })).isRequired,
  settlementMethod: PropTypes.oneOf(['self_employed', 'card']).isRequired,
  card: PropTypes.string.isRequired,
  handleChangeLanguage: PropTypes.func.isRequired,
  handleChangeCurrency: PropTypes.func.isRequired,
  handleChangeName: PropTypes.func.isRequired,
  handleChangeCount: PropTypes.func.isRequired,
  handleChangeAmount: PropTypes.func.isRequired,
  handleAddProduct: PropTypes.func.isRequired,
  handleRemoveProduct: PropTypes.func.isRequired,
  handleChangeSettlementMethod: PropTypes.func.isRequired,
  handleChangeCard: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  phoneRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({}),
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  handleChangePhone: PropTypes.func.isRequired,
  firstNameRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({}),
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  firstName: PropTypes.string.isRequired,
  handleChangeFirstName: PropTypes.func.isRequired,
  lastNameRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({}),
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  lastName: PropTypes.string.isRequired,
  handleChangeLastName: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default Presenter
