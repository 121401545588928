import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import NotFound from '../NotFound'
import { CreateFormWrap } from '../../Components'

import Api from '../../Api'
import apiRequest from '../../request'

import Preview from '../Preview'
import Requisites from '../Requisites'
import Link from '../Link'

class Invoice extends PureComponent {
  constructor(props) {
    super(props)

    this.requisitesRef = React.createRef()

    const {
      location: { hash },
    } = props

    let stage = 'preview'
    let invoiceId = ''
    if (hash.search('requisites') !== -1) {
      stage = 'requisites'
    } else if (hash.search('link') !== -1) {
      stage = 'link'
      // eslint-disable-next-line prefer-destructuring
      invoiceId = hash.split('-')[1]
      this.getInvoice(invoiceId)
    }

    this.state = {
      invoiceId,
      billId: '',
      isCurrenciesLoad: false,
      isLanguagesLoad: false,
      isRatesLoad: false,
      stage,
      currenciesList: [],
      languagesList: [],
      ratesList: [],
      isNotFound: false,
      errors: [],
    }

    this.getCurrencies()
    this.getLanguages()
    this.getRates()
  }

  getInvoice = (publicId) => {
    apiRequest({
      dispatchFunctions: {
        success: (body) => {
          this.setState({
            billId: body.number,
            stage: 'link',
          })
        },
        error: (errors) => {
          this.setState({ errors })
        },
      },
      errorCallback: () => {
        this.setState({ isNotFound: true })
      },
      request: () => Api.getInvoice(publicId),
    })
  }

  getCurrencies = () => {
    apiRequest({
      dispatchFunctions: {
        success: (body) => {
          this.setState({
            isCurrenciesLoad: true,
            currenciesList: body.items,
          })
        },
        error: () => this.setState({ isCurrenciesLoad: true }),
      },
      request: () => Api.getCurrencies(),
    })
  }

  getLanguages = () => {
    apiRequest({
      dispatchFunctions: {
        success: (body) => {
          this.setState({
            isLanguagesLoad: true,
            languagesList: body.items,
          })
        },
        error: () => this.setState({ isLanguagesLoad: true }),
      },
      request: () => Api.getLanguages(),
    })
  }

  getRates = () => {
    apiRequest({
      dispatchFunctions: {
        success: (body) => {
          this.setState({
            isRatesLoad: true,
            ratesList: body.items,
          })
        },
        error: () => this.setState({ isRatesLoad: true }),
      },
      request: () => Api.getRates({
        merchant_type: 'self_employed',
        pattern: 'invoice',
        category: 'donation',
      }),
    })
  }

  getTitle = () => {
    const {
      stage,
      billId,
      isNotFound,
    } = this.state

    if (isNotFound) {
      return 'Ошибка 404'
    }

    switch (stage) {
      case 'preview':
        return 'Выставить счёт'
      case 'requisites':
        return 'Новый счёт'
      case 'link':
        return `Счёт №${billId}`
      default:
        return null
    }
  }

  getHandleClose = () => {
    const {
      stage,
    } = this.state
    if (stage === 'requisites' || stage === 'link') {
      return () => this.setState({ stage: 'preview' })
    }
    return null
  }

  clearError = (parameter) => {
    this.setState((prevState) => ({
      errors: prevState.errors.map((errors) => {
        if (errors.parameter === parameter) {
          return {}
        }
        return errors
      }),
    }))
  }

  getForm = () => {
    const {
      history: { push },
    } = this.props
    const {
      invoiceId,
      stage,
      errors,
      currenciesList,
      languagesList,
      ratesList,
    } = this.state
    const {
      handleSubmitPreview,
      handleSubmitRequisites,
      clearError,
    } = this

    switch (stage) {
      case 'preview':
        return <Preview push={push} handleSubmit={handleSubmitPreview} />
      case 'requisites':
        return (
          <Requisites
            ref={this.requisitesRef}
            push={push}
            currenciesList={currenciesList}
            languagesList={languagesList}
            ratesList={ratesList}
            errors={errors}
            clearError={clearError}
            handleSubmit={handleSubmitRequisites}
          />
        )
      case 'link':
        return <Link push={push} {...{ invoiceId }} />
      default:
        return null
    }
  }

  handleSubmitPreview = () => {
    this.setState({ stage: 'requisites' })
  }

  handleSubmitRequisites = (requisites) => {
    const {
      language,
      currency,
      customer: {
        phone,
        card,
      },
      seller: {
        firstName,
        lastName,
      },
      products,
    } = requisites
    const items = products.map((product) => ({
      name: product.name,
      price: product.amount,
      quantity: product.count.toString(),
    }))
    const postBody = {
      customer: {
        language,
        currency,
        settlement_method: 'balance',
        phone: phone.replace(/\s/g, ''),
        card,
      },
      seller: {
        first_name: firstName,
        last_name: lastName,
      },
      items,
    }

    apiRequest({
      dispatchFunctions: {
        success: (body) => {
          this.setState({
            invoiceId: body.public_id,
            billId: body.number,
            stage: 'link',
          })
        },
        error: (errors) => {
          this.setState({ errors }, () => {
            this.requisitesRef.current.focusOnFirstError()
          })
        },
      },
      request: () => Api.postInvoice(postBody),
    })
  }

  render() {
    const {
      history: {
        push,
      },
    } = this.props
    const { isNotFound } = this.state
    const {
      getTitle,
    } = this

    if (isNotFound) {
      return (
        <NotFound
          headerTitle={getTitle()}
          handleClose={() => {
            push('/')
            this.setState({
              isNotFound: false,
              stage: 'preview',
            })
          }}
        />
      )
    }

    const {
      isCurrenciesLoad,
      isLanguagesLoad,
      isRatesLoad,
    } = this.state
    const {
      getForm,
      getHandleClose,
    } = this

    return (
      <CreateFormWrap
        headerTitle={getTitle()}
        handleClose={getHandleClose()}
      >
        {(isCurrenciesLoad && isLanguagesLoad && isRatesLoad) ? getForm() : null}
      </CreateFormWrap>
    )
  }
}

Invoice.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default Invoice
