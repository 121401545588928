import React from 'react'
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom'

import {
  // Invoice,
  Form,
  NotFound,
} from '../Pages'

const Routes = () => (
  <BrowserRouter>
    <Switch>
      {/*
      <Route
        exact
        path="/"
        render={(routeProps) => <Invoice {...routeProps} />}
      />
      */}
      <Route
        exact
        path="/:id"
        render={(routeProps) => <Form {...routeProps} />}
      />
      <Route path="*" render={() => <NotFound />} />
    </Switch>
  </BrowserRouter>
)

export default Routes
