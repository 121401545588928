import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ReactInputMask from 'react-input-mask'

import card from './img/card_types/card.svg'
import './card-number-input.scss'
import './input-tooltip-csc.scss'
import styles from './styles.module.scss'

export default function PresenterOneLine({
  oneLineSize,
  onlyNumberCard,
  wrapStyles,
  number,
  numberMask,
  expiry,
  csc,
  cscName,
  dateValidStatus,

  handleInputKeyDown,
  beforeInputChange,
  onInputChange,
  getCardTypeImage,
  getInputError,

  numberShortRef,
  expiryRef,
  cscShortRef,
}) {
  const isCardError = !!(
    getInputError('number')
    || getInputError('expiry')
    || getInputError('csc')
  )

  return (
    <div
      className={classNames(
        styles.oneLineWrap,
        onlyNumberCard && styles.oneLineWrapInForm,
        isCardError && styles.oneLineWrapError,
        wrapStyles
      )}
    >
      <div
        className={classNames(
          styles.oneLineCardTypeWrap,
          styles[`${oneLineSize}SizeTypeWrap`]
        )}
      >
        {getCardTypeImage() || <img alt="card" src={card} className={styles.cardIcon} />}
      </div>
      <ReactInputMask mask={numberMask} maskPlaceholder={null} value={number} onChange={onInputChange}>
        <input
          ref={numberShortRef}
          size="small"
          name="number"
          id="cc-number"
          autoComplete="cc-number"
          type="tel"
          placeholder="Номер карты"
          onKeyDown={handleInputKeyDown}
          className={classNames(
            styles.oneLineInput,
            styles[`${oneLineSize}SizeInput`],
            styles.oneLineInputNumber,
            !!getInputError('number') && styles.oneLineInputInvalid
          )}
        />
      </ReactInputMask>
      {!onlyNumberCard && (
        <>
          <ReactInputMask
            mask="99 / 99"
            maskPlaceholder={null}
            value={expiry}
            beforeMaskedStateChange={beforeInputChange}
            onChange={onInputChange}
          >
            <input
              ref={expiryRef}
              size="small"
              name="expiry"
              id="cc-exp"
              autoComplete="cc-exp"
              type="tel"
              placeholder="ММ / ГГ"
              onKeyDown={handleInputKeyDown}
              className={classNames(
                styles.oneLineInput,
                styles[`${oneLineSize}SizeInput`],
                styles.oneLineInputDate,
                (
                  !!getInputError('expiry')
                  || dateValidStatus.length > 0
                ) && styles.oneLineInputInvalid
              )}
            />
          </ReactInputMask>
          <input
            value={csc}
            onChange={onInputChange}
            ref={cscShortRef}
            size="small"
            name="csc"
            id="cc-csc"
            autoComplete="cc-csc"
            type="tel"
            placeholder={cscName}
            onKeyDown={handleInputKeyDown}
            className={classNames(
              styles.oneLineInput,
              styles[`${oneLineSize}SizeInput`],
              styles.oneLineInputCsc,
              !!getInputError('csc') && styles.oneLineInputInvalid
            )}
          />
        </>
      )}
    </div>
  )
}

PresenterOneLine.propTypes = {
  oneLineSize: PropTypes.oneOf([
    'small',
    'default',
    'large',
  ]),
  onlyNumberCard: PropTypes.bool,
  wrapStyles: PropTypes.string,
  number: PropTypes.string.isRequired,
  numberMask: PropTypes.string.isRequired,
  expiry: PropTypes.string,
  csc: PropTypes.string,
  cscName: PropTypes.string.isRequired,
  dateValidStatus: PropTypes.string.isRequired,
  handleInputKeyDown: PropTypes.func.isRequired,
  beforeInputChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  getCardTypeImage: PropTypes.func.isRequired,
  getInputError: PropTypes.func.isRequired,

  numberShortRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  expiryRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  cscShortRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
}
