import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Form as AntForm } from 'antd'

// eslint-disable-next-line import/no-cycle
import { isMoney } from '../../Constants/helpers'

import Input from '../Input'

import styles from './styles.module.scss'

const FormItem = AntForm.Item

class AmountInput extends PureComponent {
  static propTypes = {
    inputRef: PropTypes.shape({}),
    autoFocus: PropTypes.bool,
    isDisable: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'default', 'large']),
    name: PropTypes.string,
    value: PropTypes.string,
    currencySymbol: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    innerErrorShow: PropTypes.bool,
    isInvalid: PropTypes.bool,
    invalidTooltipText: PropTypes.string,
    handleChange: PropTypes.func,
    handleInputChange: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
  }

  static defaultProps = {
    innerErrorShow: false,
    isDisable: false,
    name: null,
    currencySymbol: null,
    error: '',
    handleChange: () => {},
    handleInputChange: () => {},
  }

  constructor(props) {
    super(props)

    this.state = {
      value: this.props.value,
      errorText: '',
    }
  }

  handleChange = (e) => {
    const { value, name } = e.target
    const { max } = this.props
    const {
      getErrorText,
      props: {
        handleChange,
        handleInputChange,
      },
    } = this

    if (isMoney(value) && Number(value) <= max) {
      const errorText = getErrorText(value)

      this.setState({
        value,
        errorText,
      }, () => {
        handleChange(value)
        handleInputChange({ target: { name, value } })
      })
    }
  }

  getErrorText = (value) => {
    const {
      min,
      max,
    } = this.props
    const number = Number.parseFloat(value)
    if (value === '') return ''
    if (isNaN(number)) return 'Неверный формат суммы' // eslint-disable-line no-restricted-globals
    if (min && (number < min)) return `Не менее ${min}\u00A0р.`
    if (max && (number > max)) return `Не более ${max}\u00A0р.`
    return ''
  }

  getIsInvalid = () => {
    const { innerErrorShow } = this.props
    const { errorText } = this.state
    const isErrorHasText = !!errorText.length
    return isErrorHasText && innerErrorShow
  }

  render() {
    const {
      inputRef,
      autoFocus,
      isDisable,
      size,
      label,
      name,
      currencySymbol,
      className,
      isInvalid,
      invalidTooltipText,
    } = this.props

    const {
      value,
      errorText,
    } = this.state

    const {
      getIsInvalid,
      handleChange,
    } = this

    return (
      <FormItem
        hasFeedback
        validateStatus={(isInvalid || getIsInvalid()) ? 'error' : ''}
      >
        <Input
          inputRef={inputRef}
          autoFocus={autoFocus}
          size={size}
          placeholder="0.00"
          name={name}
          label={label}
          pattern={/^\d+(\.\d{1,2})?$/}
          value={value}
          isDisable={isDisable}
          isInvalid={isInvalid || getIsInvalid()}
          invalidTooltipText={invalidTooltipText || errorText}
          onChange={handleChange}
          className={className}
          suffix={currencySymbol && (
            <span className={classNames(styles.sumInputSuffix, size === 'large' && styles.sumInputSuffixLarge)}>
              {currencySymbol}
            </span>
          )}
        />
      </FormItem>
    )
  }
}

AmountInput.propTypes = {}

export default AmountInput
