import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
// import { Modal } from 'antd'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'

import apiRequest from '../../request'
import Api from '../../Api'

import NotFound from '../NotFound'

import Presenter from './presenter'
import PresenterMobile from './presenter.mobile'

import styles from './form.module.scss'

class Form extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isInvoiceLoad: false,
      isNotFound: false,
      number: '',
      createdAt: '',
      // currency: '',
      language: 'RU',
      status: '',
      amount: {
        value: '',
        currency: '',
      },
      offerUrl: '',
      sellerName: '',
      paymentId: null,
      products: [],
      stackNotificationPopup: [],
    }
    window.Chatra('hide')
    NProgress.configure({ parent: '#nprogressWrapper' })
    this.currentYear = new Date().getFullYear().toString()

    this.getInvoice()
  }

  getInvoice = () => {
    apiRequest({
      dispatchFunctions: {
        success: (body) => {
          const {
            number,
            status,
            created_at: createdAt,
            // customer: {
            //   currency,
            //   language,
            // },
            amount,
            items,
            payment_id: paymentId,
            offer_url: offerUrl,
          } = body
          this.setState({
            number,
            // currency,
            language: (body.metadata && body.metadata.language) ? body.metadata.language : 'RU',
            status,
            createdAt,
            amount,
            sellerName: body.seller_name || body.domain,
            paymentId,
            products: items,
            offerUrl,
          }, () => this.setState({ isInvoiceLoad: true }))
        },
        error: (errors) => {
          this.setState({
            isNotFound: true,
            errors,
          })
        },
      },
      request: () => Api.getInvoice(this.props.match.params.id),
      errorCallback: () => {
        window.Chatra('show')
        this.setState({ isNotFound: true })
      },
    })
  }

  getCurrentDate = (createdAt) => {
    const date = new Date(createdAt)
    const { language } = this.state
    const isRu = language === 'RU'
    const locales = isRu ? 'ru-RU' : 'en-US'
    if (isMobile) {
      const curDate = new Intl.DateTimeFormat(
        locales,
        { month: 'long', day: '2-digit' }
      ).format(date)
      const curTime = new Intl.DateTimeFormat(
        locales,
  { hour: 'numeric', minute: 'numeric', timeZone: 'UTC' }
      ).format(date)
      return `${curDate} ${isRu ? 'в' : 'in'}\u00A0${curTime}`
    }
    return new Intl.DateTimeFormat(
      locales,
      { year: 'numeric', month: 'long', day: '2-digit' }
    ).format(date)
  }

  currencySymbol = () => {
    const { amount: { currency } } = this.state
    switch (currency) {
      case 'RUB':
        return '₽'
      case 'USD':
        return '$'
      default:
        return '₽'
    }
  }

  handleOpenBill = () => {
    this.handleCreateBill()

    // const {
    //   number,
    //   language,
    // } = this.state
    // const isRu = language === 'RU'

    // if (isMobile) {
    //   const confirmation = window.confirm(
    //     isRu
    //       ? `Все товары и/или услуги по счёту ${number} были предоставлены в полном объёме и надлежащего качества. Претензий не имею.`
    //       : `All goods and/or services under account ${number} were provided in full and of proper quality. I have no complaints.`
    //   )
    //   if (confirmation) {
    //     this.handleCreateBill()
    //   }
    // } else {
    //   Modal.confirm({
    //     width: 416,
    //     destroyOnClose: true,
    //     className: 'ant-modal-custom',
    //     title: (
    //       isRu
    //         ? 'Вы подтверждаете?'
    //         : 'You confirm?'
    //     ),
    //     content: (
    //       <>
    //         {
    //           isRu
    //             ? 'Все товары и/или услуги по счёту '
    //             : 'All goods and/or services under account '
    //         }
    //         <span className={styles.modalTextBold}>{`${number}`}</span>
    //         {
    //           isRu
    //             ? ' были предоставлены в полном объёме и надлежащего качества. Претензий не имею.'
    //             : ' were provided in full and of proper quality. I have no complaints.'
    //         }
    //       </>
    //     ),
    //     okText: (isRu ? 'Да' : 'Yes'),
    //     cancelText: (isRu ? 'Нет' : 'No'),
    //     onOk: this.handleCreateBill,
    //     okButtonProps: {
    //       className: 'ant-modal-custom-ok',
    //     },
    //     cancelButtonProps: {
    //       className: 'ant-modal-custom-cancel',
    //     },
    //   })
    // }
  }

  handleCreateBill = () => {
    const {
      match: {
        params: {
          id,
        },
      },
    } = this.props

    apiRequest({
      dispatchFunctions: {
        success: (body) => {
          const {
            confirmation: {
              type,
              confirmation_url: confirmationUrl,
            },
          } = body
          if (type === 'redirect') {
            window.location.href = confirmationUrl
          }
        },
        error: () => {},
      },
      errorCallback: () => {
        this.setState((presState) => ({
          stackNotificationPopup: [
            ...presState.stackNotificationPopup,
            0,
          ],
        }), () => {
          setTimeout(() => {
            this.setState((prevState) => ({
              stackNotificationPopup: prevState.stackNotificationPopup.splice(
                0,
                prevState.stackNotificationPopup.length - 1
              ),
            }))
          }, 4650)
        })
      },
      request: () => Api.postCreatePayment(id),
    })
  }

  render() {
    const {
      history: {
        push,
      },
    } = this.props
    const { isNotFound } = this.state

    if (isNotFound) {
      return (
        <NotFound
          headerTitle="Ошибка 404"
          handleClose={() => push('/')}
        />
      )
    }

    const { isInvoiceLoad } = this.state
    if (!isInvoiceLoad) return null

    const {
      number,
      createdAt,
      language,
      status,
      amount: {
        value: amountValue,
        currency,
      },
      sellerName,
      products,
      paymentId,
      stackNotificationPopup,
      offerUrl,
    } = this.state
    const {
      currentYear,
      getCurrentDate,
      currencySymbol,
      handleOpenBill,
    } = this

    const isRu = language === 'RU'

    return (
      <>
        {!isMobile && stackNotificationPopup.length > 0 && (
          <span
            className={classNames(
              styles.notificationDot,
              stackNotificationPopup.length > 0 && styles.notificationDotShow
            )}
          />
        )}
        <Helmet>
          <title>{`${isRu ? 'Счёт' : 'Invoice'} ${number} :. tome`}</title>
        </Helmet>
        {isMobile
          ? (
            <PresenterMobile
              {...{
                isRu,
                number,
                currency,
                status,
                amountValue,
                sellerName,
                products,
                paymentId,
                currentDate: getCurrentDate(createdAt),
                currencySymbol: currencySymbol(),
                handleOpenBill,
                offerUrl,
              }}
            />
          )
          : (
            <Presenter
              {...{
                isRu,
                number,
                currency,
                status,
                amountValue,
                sellerName,
                products,
                paymentId,
                currentDate: getCurrentDate(createdAt),
                currencySymbol: currencySymbol(),
                handleOpenBill,
                currentYear,
              }}
            />
          )}
      </>
    )
  }
}

Form.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

export default Form
