import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import {
  CreateFormWrap,
  CardPCI,
  EmptyCard,
} from '../../Components'

import illustration from './img/illustration.png'

import styles from './styles.module.scss'

const NotFound = (props) => (
  <CreateFormWrap
    headerTitle={props.headerTitle}
    handleClose={props.handleClose}
  >
    <CardPCI
      pciText={(
        <>
          <span>Не переживайте,</span>
          <span>мы всегда поможем</span>
        </>
      )}
      cancelText="Служба поддержки"
      handleCancelPress={() => window.Chatra('openChat', true)}
      isSubmitHide
    >
      <Helmet>
        <title>Страница не найдена :. tome</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className={styles.box}>
        <EmptyCard
          iconSrc={illustration}
          iconClassName={styles.icon}
          title="Страница не найдена"
          text={'Такой страницы не\u00A0существует. Попробуйте вернуться на\u00A0главную и\u00A0продолжить.'}
        />
      </div>
    </CardPCI>
  </CreateFormWrap>
)

NotFound.propTypes = {
  headerTitle: PropTypes.string,
  handleClose: PropTypes.func,
}

export default NotFound
