import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
  getFirstPartOfSum,
  getLastPartOfSum,
} from '../../Constants/helpers'

import { Button } from '../../Components'

import dash from '../../img/dash.svg'
import logo from '../../img/logo_short.svg'

import styles from './mobile.module.scss'

function Presenter({
  isRu,
  number,
  status,
  amountValue,
  sellerName,
  products,
  paymentId,
  currentDate,
  currencySymbol,
  handleOpenBill,
  offerUrl,
}) {
  const lastPartOfSum = getLastPartOfSum(amountValue)
  const sum = `${getFirstPartOfSum(amountValue)}${lastPartOfSum !== '00' ? `,${lastPartOfSum}` : ''}`
  const isStatusSuccess = (status === 'succeeded' || status === 'confirmation')
  return (
    <>
      <header className={styles.header}>
        <h1 className={styles.billNumber}>
          {sellerName}
        </h1>
        <div className={styles.logoWrap}>
          <img alt="" src={dash} className={styles.logoDash} />
          <img alt="tome" width="21" src={logo} />
        </div>
      </header>
      <section className={styles.content}>
        <h2 className={styles.seller}>{`${isRu ? 'Счёт' : 'Invoice'} ${number}`}</h2>
        <p className={styles.description}>
          {`${isRu ? 'Выставлен' : 'Issued'} ${currentDate}`}
        </p>
      </section>
      <section className={styles.productSection}>
        {products.map((product, index) => (
          <div key={index} className={styles.product}>
            <div className={styles.productLine}>
              <span className={styles.productTitle}>{`${isRu ? 'Наименование' : 'Description'}`}</span>
              <span className={styles.productText}>{product.name}</span>
            </div>
            <div className={styles.productRow}>
              <div className={styles.productLine}>
                <span className={styles.productTitle}>{`${isRu ? 'Цена' : 'Price'}`}</span>
                <span className={styles.productText}>
                  {`${getFirstPartOfSum(product.price)},${getLastPartOfSum(product.price)} `}
                  <span className={styles.currency}>{currencySymbol}</span>
                </span>
              </div>
              <div className={styles.productLine}>
                <span className={styles.productTitle}>{`${isRu ? 'Количество' : 'Qty'}`}</span>
                <span className={styles.productText}>{product.quantity}</span>
              </div>
            </div>
          </div>
        ))}
      </section>
      <div className={styles.footer}>
        {isStatusSuccess && paymentId
          ? (
            <Button
              isExternalLink
              target="_parent"
              type="white"
              wrapStyle={styles.createBill}
              to={`https://payment.tome.ru/${paymentId}/receipt`}
            >
              {`${isRu ? 'Чек платежа' : 'Receipt'}`}
            </Button>
          )
          : (
            <Button
              type="yellow"
              wrapStyle={styles.createBill}
              onClick={handleOpenBill}
            >
              {`${isRu ? 'Оплатить' : 'Pay'} ${sum} `}
              <span className={styles.currency}>{currencySymbol}</span>
            </Button>
          )}
      </div>
      {offerUrl && (
        <>
          <div className={classNames(styles.footerOffer, styles.offerWrap)}>
            <div className={styles.offerText}>
              {'Нажимая «Оплатить», вы соглашаетесь с условиями '}
              <a href={offerUrl} target="_blank" rel="noreferrer noopener" className={styles.offer}>
                публичной оферты
              </a>
            </div>
          </div>
        </>
      )}
    </>
  )
}

Presenter.propTypes = {
  isRu: PropTypes.bool.isRequired,
  number: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  amountValue: PropTypes.string.isRequired,
  sellerName: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    quantity: PropTypes.string.isRequired,
  })).isRequired,
  paymentId: PropTypes.string.isRequired,
  currentDate: PropTypes.string.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  handleOpenBill: PropTypes.func.isRequired,
  offerUrl: PropTypes.string,
}

export default Presenter
