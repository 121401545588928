import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Presenter from './presenter'

const defaultProduct = {
  name: '',
  count: 1,
  amount: '',
}

class Requisites extends PureComponent {
  constructor(props) {
    super(props)
    this.phoneRef = React.createRef()
    this.firstNameRef = React.createRef()
    this.lastNameRef = React.createRef()

    this.state = {
      language: 'RU',
      currency: 'RUB',
      products: [defaultProduct],
      customer: {
        settlementMethod: 'self_employed',
        card: '',
        phone: '',
      },
      seller: {
        firstName: '',
        lastName: '',
      },
    }
    props.push('#requisites')
  }

  focusOnFirstError = () => {
    const { errors } = this.props
    if (!errors.length) {
      this.phoneRef.current.input.focus()
      return
    }
    const isErrorInclude = (inputName) => !!errors.find((error) => error.parameter === inputName)

    if (isErrorInclude('customer.first_name')) {
      this.firstNameRef.current.input.focus()
    } else if (isErrorInclude('customer.last_name')) {
      this.lastNameRef.current.input.focus()
    } else if (isErrorInclude('customer.phone')) {
      this.phoneRef.current.input.focus()
    }
  }

  handleChangeLanguage = (language) => {
    this.setState({ language })
  }

  handleChangeCurrency = (currency) => {
    this.setState({ currency })
  }

  currencySymbol = () => {
    const { currenciesList } = this.props
    const { currency } = this.state
    const currencyObj = currenciesList.find((cur) => cur.id === currency)
    return currencyObj.symbol
  }

  handleChangeName = (productIndex, { target: { value } }) => {
    this.setState((prevState) => ({
      products: prevState.products.map((product, index) => {
        if (index === productIndex) {
          return {
            ...product,
            name: value,
          }
        }
        return product
      }),
    }))
  }

  handleChangeCount = (productIndex, value) => {
    this.setState((prevState) => ({
      products: prevState.products.map((product, index) => {
        if (index === productIndex) {
          if (!value) {
            return {
              ...product,
              count: 0,
            }
          }
          return {
            ...product,
            count: parseInt(value, 10),
          }
        }
        return product
      }),
    }))
  }

  handleChangeAmount = (productIndex, amount) => {
    this.setState((prevState) => ({
      products: prevState.products.map((product, index) => {
        if (index === productIndex) {
          return {
            ...product,
            amount,
          }
        }
        return product
      }),
    }))
  }

  handleAddProduct = () => {
    this.setState((prevState) => {
      if (prevState.products.length < 10) {
        const newProducts = [
          ...prevState.products,
          defaultProduct,
        ]
        return {
          products: newProducts,
        }
      }
      return null
    })
  }

  handleRemoveProduct = (index) => {
    this.setState((prevState) => {
      const newProducts = [...prevState.products]
      newProducts.splice(index, 1)
      return { products: newProducts }
    })
  }

  handleChangeSettlementMethod = ({ id: settlementMethod }) => {
    this.setState((prevState) => ({
      customer: {
        ...prevState.customer,
        settlementMethod,
      },
    }))
  }

  handleChangeCard = (card) => {
    this.setState((prevState) => ({
      customer: {
        ...prevState.customer,
        card,
      },
    }))
  }

  handleChangePhone = (e) => {
    const { value } = e.target
    this.props.clearError('customer.phone')
    this.setState((prevState) => ({
      customer: {
        ...prevState.customer,
        phone: value,
      },
    }))
  }

  handleChangeFirstName = (e) => {
    const { value } = e.target
    this.props.clearError('customer.first_name')
    this.setState((prevState) => ({
      seller: {
        ...prevState.customer,
        firstName: value,
      },
    }))
  }

  handleChangeLastName = (e) => {
    const { value } = e.target
    this.props.clearError('customer.last_name')
    this.setState((prevState) => ({
      seller: {
        ...prevState.customer,
        lastName: value,
      },
    }))
  }

  filterEmptyProducts = (products) => products.filter((product) => {
    if (!product.count || Number(product.amount) < 1) {
      return null
    }
    return product
  })

  handleSubmit = () => {
    const {
      language,
      currency,
      customer,
      seller,
      products,
    } = this.state

    this.props.handleSubmit({
      language,
      currency,
      products: this.filterEmptyProducts(products),
      customer,
      seller,
    })
  }

  render() {
    const {
      errors,
      currenciesList,
      languagesList,
      ratesList,
    } = this.props
    const {
      language,
      currency,
      products,
      customer: {
        settlementMethod,
        card,
        phone,
      },
      seller: {
        firstName,
        lastName,
      },
    } = this.state
    const {
      handleChangeLanguage,
      handleChangeCurrency,
      currencySymbol,
      handleChangeName,
      handleChangeCount,
      handleChangeAmount,
      handleAddProduct,
      handleRemoveProduct,
      handleChangeSettlementMethod,
      handleChangeCard,
      phoneRef,
      handleChangePhone,
      firstNameRef,
      handleChangeFirstName,
      lastNameRef,
      handleChangeLastName,
      handleSubmit,
    } = this

    return (
      <Presenter
        currencySymbol={currencySymbol()}
        {...{
          errors,
          currenciesList,
          languagesList,
          ratesList,
          language,
          currency,
          products,
          settlementMethod,
          card,
          handleChangeLanguage,
          handleChangeCurrency,
          handleChangeName,
          handleChangeCount,
          handleChangeAmount,
          handleAddProduct,
          handleRemoveProduct,
          handleChangeSettlementMethod,
          handleChangeCard,
          phoneRef,
          phone,
          handleChangePhone,
          firstNameRef,
          firstName,
          handleChangeFirstName,
          lastNameRef,
          lastName,
          handleChangeLastName,
          handleSubmit,
        }}
      />
    )
  }
}

Requisites.propTypes = {
  push: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({
    parameter: PropTypes.string,
  })),
  clearError: PropTypes.func.isRequired,
  currenciesList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    rate: PropTypes.number,
    face_value: PropTypes.number,
    symbol: PropTypes.string,
  })).isRequired,
  languagesList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  ratesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default Requisites
