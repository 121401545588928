import React from 'react'
import PropTypes from 'prop-types'

const ArrowDown = props => (
  <svg
    className={props.styles}
    style={{
      pointerEvents: 'none',
      transition: 'all .3s cubic-bezier(.645,.045,.355,1)',
    }}
    width="9px"
    height="6px"
    viewBox="0 0 9 6"
    stroke="#000104"
  >
    <g
      strokeWidth="1.3"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeOpacity="0.8"
      transform="translate(-715.000000, -30.000000)"
    >
      <polyline points="716.5 31 719.88916 34.3966932 723.303641 31" />
    </g>
  </svg>
)

ArrowDown.propTypes = {
  styles: PropTypes.string,
}

export default ArrowDown
